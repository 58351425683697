import React from 'react'
import { IoStarSharp } from "react-icons/io5";
import Title from '../../components/Title';
import VideoSection from '../Home/components/VideoSection';
function LatestNewsDetails() {
  return (
    <>
      <div className='w-[50%] mx-auto  py-5 '>
        <Title title={"آراء عملاءنا"} center={true} />
      </div>

      <div className='w-full relative h-full'>
        {/* Triangle */}
        <div className='absolute top-[-30px] left-12 w-[200px] h-[200px] '>
          <img src="/assets/home/comment/Polygon 2 (1).png" className='w-full h-full' alt="" />
        </div>
        {/* Triangle */}
        {/* Card */}
        <div className='w-[90%] mx-auto bg-[#76dbe2] bg-opacity-[11%]'>
          <div className='mt-20 mb-10 py-40 px-12'>
            <img className='lg:w-[70%] w-[90%] lg:h-[300px] h-[200px] rounded-[40px] my-5 drop-shadow-md  mx-auto object-cover ' src="/assets/home/section10/p2.png" alt="" />
            <h1 className='text-3xl font-bold '>عنوان الخبر</h1>
            <p className='text-xl mt-2' >17 مايو 2023</p>
            <div className='mx-auto lg:text-xl  py-8'>
            نحن ملتزمون بتقديم حلول الطاقة الشمسية المبتكرة التي تقلل من الأنبعاثات الضارة وتعزز للأستجابة البيئية مع شركتنا.
            نحن ملتزمون بتقديم حلول الطاقة الشمسية المبتكرة التي تقلل من الأنبعاثات الضارة وتعزز للأستجابة البيئية مع شركتنا.
            نحن ملتزمون بتقديم حلول الطاقة الشمسية المبتكرة التي تقلل من الأنبعاثات الضارة وتعزز للأستجابة البيئية مع شركتنا.
            نحن ملتزمون بتقديم حلول الطاقة الشمسية المبتكرة التي تقلل من الأنبعاثات الضارة وتعزز للأستجابة البيئية مع شركتنا.
            نحن ملتزمون بتقديم حلول الطاقة الشمسية المبتكرة التي تقلل من الأنبعاثات الضارة وتعزز للأستجابة البيئية مع شركتنا.
            </div>
          </div>
        </div>
        {/* Card */}

        
      </div>

    </>
  )
}

export default LatestNewsDetails