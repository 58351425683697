import React, { useEffect, useState } from 'react'
import Title from '../../components/Title'
import { Link, useParams } from 'react-router-dom'
import GetMethod from '../../functions/getMethod'
import { useTranslation } from 'react-i18next'
export default function ServiceDetailsPage() {
  const { id } = useParams()
  const [service, setService] = useState({})
  const { t } = useTranslation()
  const LANG = localStorage.getItem('LANGUAGE') || 'ar'
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod(`/services/${id}`)
      if (response?.status === 200 || response?.status === 201) {
        setService(response.data?.data)
      }else{
        setService(null)
      }
    }
    fetchData()
  }, [])
  return (
    <>
      <div className='w-full relative h-full'>

        {
          !service ?
            <h1>{t('لم يتم العثور على البيانات')}</h1>
            : (
              <>
                <div className='relative w-[90%] mx-auto bg-primary bg-opacity-[11%]  rounded-[55px]'>

                  <div className={`absolute top-[-10px] ${LANG === 'en' ? 'right-[-25px] rotate-90' : 'left-[-10px] '} lg:w-[200px] w-[80px] lg:h-[170px] h-[90px] `}>
                    <img src="/assets/Services/Polygon 2.png" className='w-full h-full' alt="" />
                  </div>
                  <div className='w-[70%] mx-auto mt-20 mb-10 py-5 ' style={{overflowWrap:"break-word"}}>
                    {
                      service?.name && <h1  className=' font-bold  xl:text-3xl text-2xl text-center'>{service?.name}</h1>
                    }
                  </div>

                  <div className='w-[95%] mx-auto lg:text-xl text-start pb-6' style={{overflowWrap:"break-word"}}>
                    <p  dangerouslySetInnerHTML={{ __html: service?.description?.replace(/\n/g, "<br>") }}></p>
                  </div>
                </div>
                <img src={service?.image_url ? service?.image_url : "/assets/Services/p1.jpg"} className='lg:w-[80%] w-[90%] object-cover lg:h-[600px] h-[360px] rounded-[55px] mx-auto mt-20 ' alt="" />
                <div className='w-full mt-20 mb-20 flex flex-col items-center justify-center '>
                  <Link to={`/services`} className='bg-primary text-center text-xl text-white font-bold w-[200px] h-[50px] flex justify-center items-center'>
                    {t("باقي الخدمات")}
                  </Link>
                </div>
              </>

            )
        }


      </div>
    </>
  )
}

// const Triangle = () => {
//   return (
//     <div className='w-full h-full  bg-red-400' style={{ borderLeft: '50px solid transparent', borderRight: '50px solid transparent', borderBottom: '100px solid #F7CB15' }}></div>
//   );
// };

