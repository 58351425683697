import React, { useEffect, useState } from 'react';
import ModalComponent from './Modal';
import { MdOutlineLock, MdOutlineMail, MdOutlinePhone } from 'react-icons/md';
import { FaRegUser } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import postMethod from '../functions/postMethod';
import { CiLock } from "react-icons/ci";
import Swal from 'sweetalert2';



function LoginInputs({ setLoginFormData, LoginFormData }) {
  const { t } = useTranslation();
  return (
    <>
      <div className='flex items-center p-2 rounded-full bg-black bg-opacity-[10%] text-white text-xl  w-[80%] gap-2'>
        <span><MdOutlineMail /></span>
        <input className='bg-transparent  focus:outline-none placeholder:text-white placeholder:text-opacity-50 w-[90%]' onChange={(e) => setLoginFormData({ ...LoginFormData, email: e.target.value })} placeholder={t('البريد الالكتروني')} type="text" name="email" id="" />
      </div>
      <div className='flex items-center p-2 rounded-full bg-black bg-opacity-[10%] text-white text-xl  w-[80%] gap-2'>
        <span><MdOutlineLock /></span>
        <input className='bg-transparent  focus:outline-none placeholder:text-white placeholder:text-opacity-50 w-[90%] ' onChange={(e) => setLoginFormData({ ...LoginFormData, password: e.target.value })} placeholder={t('كلمة المرور')} type="text" name="password" id="" />
      </div>
    </>
  );
}

function SignupInputs({ setRegisterFormData, RegisterFormData }) {
  const { t } = useTranslation();

  return (
    <>
      <div className='flex items-center p-2 rounded-full bg-black bg-opacity-[10%] text-white text-xl  w-[80%] gap-2'>
        <span><FaRegUser /></span>
        <input className='bg-transparent  focus:outline-none placeholder:text-white placeholder:text-opacity-50  w-[90%]' onChange={(e) => setRegisterFormData({ ...RegisterFormData, name: e.target.value })} placeholder={t('الاسم')} type="text" name="name" id="" />
      </div>
      <div className='flex items-center p-2 rounded-full bg-black bg-opacity-[10%] text-white text-xl  w-[80%] gap-2'>
        <span><MdOutlineMail /></span>
        <input className='bg-transparent  focus:outline-none placeholder:text-white placeholder:text-opacity-50 w-[90%] ' onChange={(e) => setRegisterFormData({ ...RegisterFormData, email: e.target.value })} placeholder={t('البريد الالكتروني')} type="text" name="email" id="" />
      </div>
      <div className='flex items-center p-2 rounded-full bg-black bg-opacity-[10%] text-white text-xl  w-[80%] gap-2'>
        <span><CiLock /></span>
        <input className='bg-transparent  focus:outline-none placeholder:text-white placeholder:text-opacity-50 w-[90%] ' onChange={(e) => setRegisterFormData({ ...RegisterFormData, password: e.target.value })} placeholder={t('كلمة المرور')} type="text" name="phone" id="" />
      </div>
      <div className='flex items-center p-2 rounded-full bg-black bg-opacity-[10%] text-white text-xl  w-[80%] gap-2'>
        <span><CiLock /></span>
        <input className='bg-transparent  focus:outline-none placeholder:text-white placeholder:text-opacity-50 w-[90%] ' onChange={(e) => setRegisterFormData({ ...RegisterFormData, password_confirmation: e.target.value })} placeholder={t('تاكيد كلمة المرور')} type="text" name="password" id="" />
      </div>

    </>
  );
}

export default function AuthModal({ isModalOpen, setIsModalOpen }) {
  const [RegisterFormData, setRegisterFormData] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
  })

  const [LoginFormData, setLoginFormData] = useState({
    email: '',
    password: '',
  })
  const [user, setUser] = useState('');
  const Register = async () => {
    const res = await postMethod('/user/register', RegisterFormData)
    if (res?.status == 200 || res?.status == 201) {
      setUser(res.data?.user)
      localStorage.setItem('user', JSON.stringify(res.data))
      closeModal()
      Swal.fire({
        icon: 'success',
        title: 'تم التسجيل بنجاح',
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'يرجي ادخال بيانات صحيحة',
      })
    }
  }
  const Login = async () => {
    const res = await postMethod('/user/login', LoginFormData)
    if (res?.status == 200 || res?.status == 201) {
      setUser(res.data?.user)
      localStorage.setItem('user', JSON.stringify(res.data))
      closeModal()
    } else {
      Swal.fire({
        icon: 'error',
        title: 'البريد الالكتروني او كلمة المرور غير صحيحة',
      })
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (isLoginMode) {
      Login();
    } else {
      Register();
    }
  }
  const [isLoginMode, setIsLoginMode] = useState(false);
  const { t } = useTranslation();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleMode = () => {
    setIsLoginMode(prevMode => !prevMode);
  };

  useEffect(() => {
    if (!localStorage.getItem('user') && user?.id == undefined) {
        setTimeout(() => {
          openModal();
        }, 1000 * 60 * 5 );
    }
  }, []);

  return (
    <form className='z-[99]'>
      <ModalComponent onSubmit={onSubmit} Register={Register} Login={Login} RegisterFormData={RegisterFormData} setRegisterFormData={setRegisterFormData} setLoginFormData={setLoginFormData} isOpen={isModalOpen} >
        <div className='bg-secondary rouded-lg p-4  flex flex-col items-center justify-between w-full h-[450px]'>
          <h1 className='bg-white bg-opacity-[18%] text-2xl font-bold text-white p-3  rounded-full'>
            {isLoginMode ? t('تسجيل الدخول') : t('إنشاء حساب')}
          </h1>

          <div className='flex flex-col gap-6 items-center justify-center w-full'>
            {isLoginMode ? <LoginInputs setLoginFormData={setLoginFormData} LoginFormData={LoginFormData} /> : <SignupInputs RegisterFormData={RegisterFormData} setRegisterFormData={setRegisterFormData} />}
          </div>

          <div className='flex flex-col items-center justify-between w-full'>
            <button onClick={onSubmit} className='bg-white text-secondary px-3 p-1 rounded-full w-[200px] '>
              {isLoginMode ? t('تسجيل الدخول') : t('إنشاء حساب')}
            </button>
            <p className='text-white text-opacity-50 cursor-pointer' onClick={toggleMode}>
              {isLoginMode ? t('إنشاء حساب جديد') : t('تسجيل الدخول')}
            </p>
          </div>
        </div>
      </ModalComponent>
    </form>
  );
}
