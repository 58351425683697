import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import GetMethod from './../../../functions/getMethod';

export default function Section3() {
  const { t } = useTranslation();
    const [youtubeLink, setYoutubeLink] = useState("");
    useEffect(() => {
      
      const getYoutubeLink = async () => {
        const response = await GetMethod("/social-links");

        if (response?.status == 200 || response?.status == 201) {
          setYoutubeLink(response?.data?.social_links[0]?.youtube);
        }
      }

      getYoutubeLink();
    }, [])
  return (
    <section className='mt-40 '>
      <div className=' w-[90%] text-center mx-auto flex flex-col items-center justify-center'>
        <h1 className='text-3xl font-bold'>{t("نتميز بالكفاءة والإحترافيه")}</h1>
        <p className='lg:w-[60%] text-center mx-auto mt-2'>{t("نسعي في إعمار الأرض وتسهيل التعامل مع أحدث الوسائل التكنولوچية علي غير المتخصصين من خلال مواكبة أحدث التطورات العلمية وتقديم الدعم الفني والمعلوماتي بطرق متعددة")}</p>

        <div className='flex  items-center justify-center lg:gap-6 gap-1 my-14 w-full mx-auto '>
          <Card to={"/services"} title="إنتاج الكهرباء من الطاقة المتجددة النظيفة" image="/assets/home/section3/v3.svg" />
          <Card to={"/services"} title="تصميم وتنفيذ الأنظمة التكنوچية الحديثة" image="/assets/home/section3/v2.svg" />
          <Card to={"/contact"} title="تقديم الدعم الفني والإستشارات الهندسية" image="/assets/home/section3/v1.svg" />
          <Card to={youtubeLink ? youtubeLink : "https://www.youtube.com/@GOMAACOMPANY"} title="نشر حلقات تعليمية علي اليوتيوب" image="/assets/home/section3/v4.svg" />
        </div>
      </div>
    </section>
  )
}


function Card({ title, image ,to}) {
  const { t } = useTranslation();

  return (
    <Link to={to} data-aos="fade-up"  className=' w-[400px] lg:h-[195px] h-[160px]  bg-primary bg-opacity-15 rounded-lg lg:p-10 p-2 flex flex-col items-center gap-3 text-primary justify-center relative after:bg-primary after:absolute after:bottom-0 after:left-0 after:w-full after:h-0 after:z-[-1] z-[2] hover:after:h-full hover:text-white after:transition-all group'>
      <img className='group-hover:bg-white rounded p-1 h-14 w-14' src={image} alt="" />
      <h1 className='lg:text-lg md:text-base text-xs '>{t(title)}</h1>
    </Link>
  )
}