
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import GetMethod from '../../../functions/getMethod'

export default function Section10() {
  const { t } = useTranslation()

  const [posts, setPosts] = useState([])

  useEffect(() => {
    const getData = async () => {
      const response = await GetMethod("/posts");
      if (response?.status === 200 || response?.status === 201) {
        setPosts(response.data?.data.slice(0, 3));
      }
    }


    getData()
  }, [])
  return (
    <section className=' mt-20'>
      <h1 className='text-4xl font-bold  text-center '>{t("أخر")} <span className='text-primary'>{t("الأخبار")}</span></h1>

      {
        posts.length > 0 ? (
          <div className='flex items-center justify-center gap-3 mt-10'>
            {
              posts?.map((post, index) => (
                <Card key={index} link={`/post/${post.id}`} description={post.description} image={post.image} icon={post.icon} />
              ))
            }
          </div>
        )
          :
          (<h1 className='text-3xl text-center  font-bold my-10 mt-14'>{t("لا يوجد مقالات")}</h1>)
      }

      <div className='w-full mt-10 flex flex-col items-center justify-center '>
        <Link to={`/blog`} className='bg-primary text-center text-xl text-white font-bold w-[200px] h-[50px] flex justify-center items-center'>
          {t("باقي المدونات")}
        </Link>
      </div>

    </section>
  )
}


function Card({ description, image, icon = "/assets/home/section10/v1.svg", link }) {
  const { t } = useTranslation()
  return (
    <Link data-aos="fade-up" to={link} className='lg:w-[480px] w-[225px] relative  lg:min-h-[520px]  flex flex-col items-center pb-10 lg:justify-between  gap-8 bg-[#E9E9E9] bg-opacity-[33%] group '>
      <div className='w-full lg:h-[300px] h-[200px] relative'>
        <img className='h-full w-full object-cover drop-shadow' src={image} alt="" />
        <div className='bg-primary absolute  rotate-45 bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 lg:w-[80px] w-[40px] lg:h-[80px] h-[40px] flex items-center justify-center'>
          <img className='w-[60%] -rotate-45 object-cover' src={icon} alt="" />
        </div>

      </div>

      <p className='text-center lg:text-2xl  px-1'>{description.slice(0,50).includes(' ') ? description.split(' ').slice(0, 12).join(' ') + '...'  : description.slice(0, 20)}</p>

    </Link>
  )

}
