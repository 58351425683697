import React, { useRef, useEffect } from 'react';
import Flickity from 'flickity';

const Carousel = ({ customStyles, children, carouselClass, dots = true, autoPlay = false, loop = false,loading=false }) => {
  const flickityRef = useRef(null);

  useEffect(() => {

    if(loading){
      return
    }

    if (flickityRef.current) {
      flickityRef.current.destroy(); // Destroy any existing Flickity instance before reinitializing
    }

    flickityRef.current = new Flickity(`.${carouselClass}`, {
      draggable: true,
      pageDots: dots,
      contain: true,
      autoPlay: autoPlay,
      wrapAround: loop,
    },);

    return () => {
      // Clean up Flickity instance when component unmounts
      if (flickityRef.current) {
        flickityRef.current.destroy();
      }
    };
  }, [carouselClass,,loading]); 

  return (
    <div className={`focus:outline-none ${customStyles} ${carouselClass}`}>
      {children}
    </div>
  );
};

export default Carousel; 