import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { FaFacebook, FaRegUser, FaUser, FaUserCircle, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import { CiLocationOn, CiUser } from 'react-icons/ci';
import { TfiWorld } from 'react-icons/tfi';
import Dropdown from './Dropdown';
import { useTranslation } from 'react-i18next';
import { isBrowser, isMobile } from 'react-device-detect';
import ModalComponent from './Modal';
import Profile from './Profile';
import AuthModal from './AuthModal';
import { IoIosMenu } from "react-icons/io";
import { IoClose, IoCloseOutline } from 'react-icons/io5';

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false)
  const [isProfileOpen, setIsProfileOpen] = useState(false)
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { t } = useTranslation()


  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Clicked outside of the div
       setIsMenuOpen(false)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 250) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const openProfile = () => {
    setIsMenuOpen(false)
    const user = JSON.parse(localStorage.getItem("user"))
    if (user) {
      setIsProfileOpen(true)
    } else {
      setIsAuthModalOpen(true)
    }
  }

  const Links = [
    { title: "الرئـيـسـيـة", url: "/" },
    { title: "مـن نـحـن", url: "/about-us" },
    { title: "خـدمـاتـنـا", url: "/services" },
    { title: "طلب سعر", url: "/request-price" },
    { title: "المشاريع", url: "/projects" },
    { title: "الـمـدونـة", url: "/blog" },
    { title: "اتـصـل بـنـا", url: "/contact-us" },
  ]
  return (
    <>
      {
        isScrolled ? <div className='w-full h-40 bg-white' /> : null
      }


      <nav className={`${isScrolled ? 'bg-white fixed text-black drop-shadow ' : 'absolute'} top-0 left-0 w-full z-[99] transition-all`}>
        <section className={`w-full  h-12  bg-secondary flex items-center justify-between  md:px-5 px-1 gap-1 lg:gap-10 transition-all ${isScrolled ? 'hidden' : ''} `}>

          <div className=" h-full flex items-center justify-between  lg:gap-4 text-white whitespace-nowrap text-xs lg:text-base">
            <div className='flex items-center justify-center'>
              <CiLocationOn className='lg:text-lg ' />

              <h1 className='text-[6px] lg:text-base text-nowrap' >{t("مطروح - سيوة -السوق - أمام فندق النخيل")}</h1>
              {
                isBrowser && <h1 className='text-wrap text-[6px] md:text-base mx-2' >|</h1>
              }
              <h1 className=' text-[6px] lg:text-base  text-nowrap' >{t("الجيزة - 6 أكتوبر - محور بوليفار - أمام كمبوند جراند هايتس")}</h1>

            </div>
          </div>

          <div className=" h-full flex items-center  justify-center gap-1 lg:gap-3 text-xs lg:text-base">

            <div className='flex items-center justify-center text-white text-[11px] lg:text-base '>
              <AiOutlineMail />
              <Link className='mx-1' to="mailto:info@gomaacompany.com">info@gomaacompany.com</Link>
            </div>

            <Link target='_blank' to="https://www.facebook.com/profile.php?id=100063806260379&mibextid=JRoKGi"><FaFacebook className="lg:text-base text-sm text-white " /></Link>
            <Link target='_blank' to="https://youtube.com/@GOMAACOMPANY?si=MLQLIgQvf0bM08uJ"><FaYoutube className="lg:text-base text-sm text-white " /></Link>
            <Link target='_blank' to="https://wa.me/+201008309836"><FaWhatsapp className="lg:text-base text-sm text-white " /></Link>

          </div>

        </section>



        <section className={`flex items-center  relative justify-around lg:w-[90%] mx-auto  mt-4  ${isScrolled ? 'h-[84px]' : ''} transition-all`}>
          <img className='xl:w-32  w-40 object-cover' src="/assets/logo.png" alt="" />

          <div className=' items-center justify-center xl:gap-4 gap-2  lg:w-full  hidden lg:flex'>
            {Links?.map((link, index) => (
              <NavLink className={`${location.pathname == "/" && !isScrolled ? 'text-white' : 'text-black'} lg:text-lg text-xs font-bold hover:text-secondary transition-all`} to={link.url} key={index}>{t(link.title)}</NavLink>
            ))}
          </div>




            <div className='flex items-center justify-around  w-[120px] px-2 gap-5 '>

          <div onClick={() => setIsOpen(!isOpen)} className={`relative xl:text-3xl text-2xl cursor-pointer flex ${location.pathname == "/" && !isScrolled ? 'text-white' : 'text-black'} `}><TfiWorld />
            {isOpen && <>
              <Dropdown />
            </>}
          </div>
          <div onClick={() => setIsMenuOpen(!isMenuOpen)}  className={`lg:hidden flex  items-center justify-center ${location.pathname == "/" && !isScrolled ? 'text-white' : 'text-black'}   `}><IoIosMenu className='text-4xl cursor-pointer'  /></div>
          </div>

          {
            isScrolled && <div onClick={openProfile} className='absolute -left-20 -bottom-14   bg-white p-4 w-14 h-14  flex items-center justify-center text-secondary cursor-pointer rounded-b-md drop-shadow-md'><FaUserCircle className='text-3xl' /></div>
          }

        </section>
      </nav>


      {
        isMenuOpen && (
          <>

            <div className='fixed w-full h-full top-0 left-0 bg-black opacity-50 z-[99] lg:hidden '></div>
            <div ref={menuRef} className='fixed lg:hidden w-[50%] bg-white h-full top-0 left-0 z-[99] drop-shadow-lg animate__animated animate__slideInLeft animate__faster'>


              <div className='flex items-center justify-around '>

                <img className='xl:w-32  w-40 object-cover' src="/assets/logo.png" alt="" />
                <div className={`flex  items-center justify-center  `}><IoCloseOutline className='text-3xl cursor-pointer' onClick={() => setIsMenuOpen(false)} /></div>
              </div>

              <div className=' items-center justify-center  gap-8  lg:w-full  flex-col flex'>
                {Links?.map((link, index) => (
                  <NavLink className={`text-black   text-xl  font-bold hover:text-secondary transition-all`} onClick={() => setIsMenuOpen(false)} to={link.url} key={index}>{t(link.title)}</NavLink>
                ))}

                <div onClick={openProfile}  className={`text-black   text-xl  font-bold hover:text-secondary transition-all cursor-pointer`}>{t("الملف الشخصي")}</div>
              </div>



            </div></>
        )
      }

      <AuthModal isModalOpen={isAuthModalOpen} setIsModalOpen={setIsAuthModalOpen} />

      {
        isProfileOpen && (
          <Profile isOpen={isProfileOpen} setIsOpen={setIsProfileOpen} />
        )
      }

    </>
  )
}
