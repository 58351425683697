import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GetMethod from './../../functions/getMethod';
import postMethod from './../../functions/postMethod';

import Swal from 'sweetalert2';
import PhoneInput from './components/PhoneInput';
export default function RequestPricePage() {
  const [questions, setQuestions] = useState([
    {
      id: 1,
      inputType: "select",
      text: "أين تقع المدينة المراد إنشاء المحطة بها؟",
      answers: [
        { id: 1, text: "القاهرة", questionId: 1, nextQuestionId: 2 },
        { id: 2, text: "الاسكندرية", questionId: 1, nextQuestionId: 2 },
        { id: 3, text: "الاسماعيلية", questionId: 1, nextQuestionId: 2 },
        { id: 4, text: "أسوان", questionId: 1, nextQuestionId: 2 },
        { id: 5, text: "أسيوط", questionId: 1, nextQuestionId: 2 },
        { id: 6, text: "الاقصر", questionId: 1, nextQuestionId: 2 },
        { id: 7, text: "البحر الأحمر", questionId: 1, nextQuestionId: 2 },
        { id: 8, text: "البحيرة", questionId: 1, nextQuestionId: 2 },
        { id: 9, text: "بني سويف", questionId: 1, nextQuestionId: 2 },
        { id: 10, text: "بورسعيد", questionId: 1, nextQuestionId: 2 },
        { id: 11, text: "جنوب سيناء", questionId: 1, nextQuestionId: 2 },
        { id: 12, text: "الجيزة", questionId: 1, nextQuestionId: 2 },
        { id: 13, text: "الدقهلية", questionId: 1, nextQuestionId: 2 },
        { id: 14, text: "دمياط", questionId: 1, nextQuestionId: 2 },
        { id: 15, text: "سوهاج", questionId: 1, nextQuestionId: 2 },
        { id: 16, text: "السويس", questionId: 1, nextQuestionId: 2 },
        { id: 17, text: "الشرقية", questionId: 1, nextQuestionId: 2 },
        { id: 18, text: "شمال سيناء", questionId: 1, nextQuestionId: 2 },
        { id: 19, text: "الغربية", questionId: 1, nextQuestionId: 2 },
        { id: 20, text: "الفيوم", questionId: 1, nextQuestionId: 2 },
        { id: 21, text: "القليوبية", questionId: 1, nextQuestionId: 2 },
        { id: 22, text: "قنا", questionId: 1, nextQuestionId: 2 },
        { id: 23, text: "كفر الشيخ", questionId: 1, nextQuestionId: 2 },
        { id: 24, text: "مطروح", questionId: 1, nextQuestionId: 2 },
        { id: 25, text: "المنوفيه", questionId: 1, nextQuestionId: 2 },
        { id: 26, text: "المنيا", questionId: 1, nextQuestionId: 2 },
        { id: 27, text: "الوادي الجديد", questionId: 1, nextQuestionId: 2 },
      ]
    },
    {
      id: 2,
      inputType: "select",
      text: "ما نوع محطة الطاقة الشمسية المراد إنشائها؟",
      answers: [
        { id: 28, text: "محطة طاقة شمسية متصلة بالشبكة (ON-Grid)", questionId: 2, nextQuestionId: 3 },
        { id: 29, text: "محطة طاقة شمسية منفصلة عن الشبكة (OFF-Grid)", questionId: 2, nextQuestionId: 7 },
        { id: 30, text: "محطة ري بالطاقة الشمسية (Pumping)", questionId: 2, nextQuestionId: 5 }
      ]
    },
    {
      id: 3,
      inputType: "select",
      text: "هل تمتلك فواتير إستهالك المنشأة للكهرباء في أخر ستة أشهر؟",
      answers: [
        { id: 31, text: "نعم", questionId: 3, nextQuestionId: 6 },
        { id: 32, text: "لا", questionId: 3, nextQuestionId: 7 },
      ]
    },
    {
      id: 6,
      inputType: "file",
      text: "من فضلك قم برفع صور فواتير إستهالك المنشأة للكهرباء في أخر ستة أشهر.",
      answers: [],
      nextQuestionId: 100
    },
    {
      id: 7,
      inputType: "table",
      text: "من فضلك قم بملئ بيانات الأجهزة الكهربائية الموجودة داخل المنشأة.",
      answers: [],
      nextQuestionId: 100
    },
    {
      id: 5,
      inputType: "select",
      text: " هل قمت بحساب كمية المياة المطلوبة يومياً ومقدار الضغط المطلوب؟",
      answers: [
        { id: 33, text: "نعم", questionId: 5, nextQuestionId: 8 },
        { id: 34, text: "لا", questionId: 5, nextQuestionId: 9 },
      ]
    },
    {
      id: 9,
      inputType: "text",
      text: " كم يبلغ مقدار مساحة الأرض الزراعية؟",
      // answers :[]
      nextQuestionId: 9.5,
      note: "فدان"
    },
    {
      id: 9.5,
      inputType: "multyText",
      text: " ما هي النباتات الرأسية المراد زراعتها في الأرض؟",
      // answers :[]
      nextQuestionId: 14,
    },
    {
      id: 14,
      inputType: "select",
      text: " ما هو نوع الري؟",
      answers: [
        { id: 35, text: " غمر/ غمر مطور", questionId: 14, nextQuestionId: 10 },
        { id: 36, text: " تنقيط", questionId: 14, nextQuestionId: 10 },
        { id: 37, text: "رش/بيفوت", questionId: 14, nextQuestionId: 10 },
      ]
    },
    {
      id: 8,
      inputType: "text",
      text: " كم يبلغ مقدار كمية المياه المطلوبة في اليوم الواحد؟",
      // answers:[]
      nextQuestionId: 8.5,
      note: "متر3"
    },
    {
      id: 8.5,
      inputType: "text",
      text: "كم يبلغ مقدار الضغط المطلوب؟",
      // answers:[]
      nextQuestionId: 10,
      note: "بار"
    },
    {
      id: 10,
      inputType: "text",
      text: " كم يبلغ مقدار المسافة الأفقية بين مصدر المياه وأبعد نقطة في الأرض الزراعية؟",
      // answers:[]
      nextQuestionId: 11,
      note: "متر"
    },
    {
      id: 11,
      inputType: "select",
      text: " ما هو مصدر المياه؟",
      answers: [
        { id: 38, text: " مجري مائي/ مصرف زراعي", questionId: 11, nextQuestionId: 12 },
        { id: 39, text: "بئر جوفي", questionId: 11, nextQuestionId: 13 }
      ]
    },
    {
      id: 12,
      inputType: 'text',
      text: " كم تبلغ المسافة الرأسية بين سطح الماء والأرض الزراعية؟",
      // answers:[]
      nextQuestionId: 100,
      note: "متر"
    },
    {
      id: 13,
      inputType: "text",
      text: " كم يبلغ مقدار المسافة الرأسية بين سطح الماء وأعلي نقطة في الأرض الزراعية؟",
      nextQuestionId: 13.5,
      note: "متر"
    },
    {
      id: 13.5,
      inputType: "text",
      text: "كم يبلغ مقدار عمق البئر؟",
      nextQuestionId: 13.9,
      note: "متر"
    },
    {
      id: 13.9,
      inputType: "text",
      text: " ما مقدار قطر البئر؟",
      nextQuestionId: 100,
      note: "بوصة"
    },
    {
      id: 100,
      inputType: "message",
      text: "هل أنت متاكد من تقديم الطلب؟",
    }
  ]);
  const { t } = useTranslation();
  const [answers, setAnswers] = useState([
    {
      nextQuestionId: 1
    }
  ]);
  const [seenQuestions, setSeenQuestions] = useState([1]);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');




  const handelSend = async (e) => {
    e.preventDefault();
    
    Swal.fire({
      title: 'جاري التحميل...',
      html: 'يرجى الانتظار بينما يتم معالجة الطلب .',
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });


    if (answers[answers.length - 1]?.nextQuestionId == 100) {
      let image;
      
      
      let data = answers.map((a, index) => {
        // check if the text is type File 
        if (Array.isArray(a?.answer?.text) && a.answer.text.every(item => item instanceof File)) {
          image = a?.answer?.text
          return {
            questionText: a?.questionText,
            answer: 'image'
          }
        }
        return {
          questionText: a?.questionText,
          answer: a?.answer?.text
        }
      })

      // remove the  first answer
      data.shift();





      const formData = new FormData();
      formData.append('phone', phoneNumber);
      email && formData.append('email', email);
      formData.append('questions[]', JSON.stringify(data));
      formData.append('answers[]', []);
      if (image != null) {
        image.forEach((file, index) => {
          formData.append(`images[${index}]`, file);
        })
      }


      const res = await postMethod("/questions-answers", formData);

      if (res?.status === 200 || res?.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'تم الارسال بنجاح',
        })

        setTimeout(() => {
          window.location.reload();
        }, 2000)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'لم يتم الارسال',
          text: 'حاول مرة اخرى',
        })
      }
    }


  }


  const handleNextQuestion = (question, answer, file) => {
    // Check if there already is an answer for this question
    const existingAnswerIndex = answers.findIndex((a) => a.questionId === question.id);

    if (existingAnswerIndex !== -1) {
      // Update the existing answer+
      const updatedAnswers = answers.map((a, index) => {
        if (index === existingAnswerIndex) {
          return { ...a, questionText: question?.text, nextQuestionId: answer.nextQuestionId || question.nextQuestionId, answer };
        }
        return a;
      });

      // Remove all answers after this question
      const updatedAnswers2 = updatedAnswers.slice(0, existingAnswerIndex + 1);
      setAnswers(updatedAnswers2);
    } else {
      // Add a new answer
      const newAnswer = { questionText: question?.text, questionId: question.id, nextQuestionId: answer.nextQuestionId || question.nextQuestionId, answer };
      setAnswers([...answers, newAnswer]);
    }

    // Add file answer if provided
    if (file) {
      const fileAnswer = { questionText: question?.text, questionId: question.id, nextQuestionId: answer.nextQuestionId || question.nextQuestionId, file };
      setAnswers([...answers, fileAnswer]);
    }
  };

  useEffect(() => {

    const ids = answers.map((a) => a.nextQuestionId)
    setSeenQuestions(ids);
  }, [answers])


  return (
    <main className='pt-10'>

      <h1 className='text-4xl font-bold text-center my-5'>{t("اعرف محطتك هتكلفك كام")}</h1>
      <form onSubmit={handelSend} className=' min-h-[800px] lg:w-[80%] w-[95%] bg-black bg-opacity-[3%] mx-auto rounded-[30px] md:p-5  flex  flex-col  '>
        <PhoneInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
        <div className='flex items-center md:w-[60%] w-[90%]  gap-2 my-2  mx-auto'>
          <input value={email} onChange={(e) => setEmail(e.target.value)} className=' rounded-[15px] p-2  w-full h-[50px] placeholder:text-secondary  bg-secondary bg-opacity-[9%] focus:outline-none ' type="email" name="" placeholder={t("ادخل  عنوان البريد الإلكتروني (اختياري)")} id="" />
        </div>
        {questions?.map((question) => (
          seenQuestions.includes(question.id) && <div className='  md:w-[90%] w-full mx-auto  my-5  '><Question key={question.id} onSend={handelSend} question={question} handleNextQuestion={handleNextQuestion} /></div>
        ))}

      </form>
    </main>
  );
}

const Question = ({ question, handleNextQuestion, onSend }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [textInput, setTextInput] = useState('');
  const [answers, setAnswers] = useState([]);

  const handleSelectChange = (option) => {
    setSelectedOption(option.text);
    handleNextQuestion(question, option);
  };

  const handleTextInputChange = (event, option) => {
    setTextInput(event.target.value);
    handleNextQuestion(question, { ...option, text: event.target.value });
  };

  const handleFileInputChange = (file) => {
    handleNextQuestion(question, { text: file });
  };

  const handleTableInputChange = (answers) => {
    handleNextQuestion(question, { text: answers });
  }

  switch (question.inputType) {
    case 'select':
      return (
        <CustomSelect
          options={question.answers}
          placeholder={question.text}
          selectedOption={selectedOption}
          onChange={handleSelectChange}
        />
      );
    case 'text':
      return (
        <TextInput
          placeholder={question.text}
          value={textInput}
          onChange={handleTextInputChange}
          note={question.note}
          option={{ text: textInput, nextQuestionId: question.nextQuestionId }}
        />
      );
    case 'multyText':
      return (
        <MultyTextInput
          placeholder={question.text}
          value={textInput}
          onChange={handleTextInputChange}
        />
      );
    case 'file':
      return <FileInput placeholder={question.text} onChange={handleFileInputChange} />;

    case 'table':
      return <TableInput placeholder={question.text} onChange={handleTableInputChange} />;

    case 'message':
      return <Message onSend={onSend} placeholder={question.text} />;
    default:
      return null;
  }
};

const CustomSelect = ({ options, placeholder, selectedOption, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="relative w-full ">
      <div
        className="rounded-[8px]  w-full h-[80px] p-4 bg-secondary bg-opacity-[9%] lg:text-base text-xs flex items-center  justify-between cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption ? (
          <span>{selectedOption}</span>
        ) : (
          <span className="text-gray-500 ">{placeholder}</span>
        )}
        <svg
          className={`w-6 h-6 ${isOpen ? 'transform rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </div>
      {isOpen && (
        <div className="max-h-[250px] overflow-y-auto top-full left-0 w-full bg-secondary bg-opacity-[9%] rounded-b-[8px] z-10 lg:text-base text-xs">
          {options?.map((option, index) => (
            <div
              key={index}
              className="p-4 cursor-pointer hover:bg-primary hover:text-white"
              onClick={() => handleOptionClick(option)}
            >
              {option.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const TextInput = ({ note, placeholder, value, onChange, option }) => {
  return (
    <section className=' w-full flex gap-2 items-end'>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e, { ...option, text: e.target.value })}
        className="rounded-[8px] w-full h-[80px] p-4 bg-secondary bg-opacity-[9%] lg:text-base text-xs"
      />
      <p className='font-bold text-lg'>{note}</p>
    </section>
  );
};



const MultyTextInput = ({ placeholder, value, onChange }) => {
  const [answers, setAnswers] = useState([])
  const [inputsLen, setInputsLen] = useState([1])
  return (
    <textarea
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="rounded-[8px]  w-full  h-[80px] p-4 bg-secondary bg-opacity-[9%] lg:text-base text-xs"
    />

  );
};
const FileInput = ({ placeholder, onChange }) => {
  const [imagePreviews, setImagePreviews] = useState([]);
  const [selectedPreview, setSelectedPreview] = useState(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const selectedFiles = [];
    setImagePreviews([]);
    
    
    // Check if number of selected files exceeds 6
    if (files.length > 6) {
      Swal.fire({
        icon: 'error',
        title: 'لا يمكنك تحميل اكثر من 6 صورة',
      })

      return
    }
    
    // Iterate through selected files
    for (let i = 0; i < files.length; i++) {
      // Check if it's an image file
      if (files[i].type.startsWith('image/')) {
        selectedFiles.push(files[i]);
        // Read the image file as a data URL for preview
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreviews((prevPreviews) => [...prevPreviews, e.target.result]);
        };
        reader.readAsDataURL(files[i]);
      }

    }

      onChange(selectedFiles);
  };


  const handlePreviewClick = (index) => {
    setSelectedPreview(imagePreviews[index]);
  };

  return (
    <div>
      <p className='my-2'>{placeholder}</p>
      <input 
        type="file" 
        onChange={handleFileChange} 
        className="rounded-[8px] w-full h-[80px] p-4 bg-secondary bg-opacity-[9%] lg:text-base text-xs" 
        multiple // Allow multiple file selection
        accept="image/*" // Specify that only image files are allowed
      />
      <div className="mt-2 flex flex-wrap gap-2">
        {imagePreviews.map((preview, index) => (
          <div key={index} className="relative">
            <img 
              src={preview} 
              alt={`Preview ${index}`} 
              className="w-16 h-16 object-cover rounded-md cursor-pointer"
              onClick={() => handlePreviewClick(index)}
            />
            
          </div>
        ))}
      </div>
      {selectedPreview && (
        <div className="mt-4">
          <img src={selectedPreview} alt="Selected Preview" className="max-w-full object-cover rounded-md h-[300px]" />
          <button 
            className="mt-2 p-1 bg-white rounded-md text-gray-500 hover:text-red-500"
            onClick={() => setSelectedPreview(null)}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};


const TableInput = ({ placeholder, onChange }) => {
  const [rows, setRows] = useState([
    { type: '', quantity: '', wattsPerDevice: '', dayOperationHours: '', nightOperationHours: '' }
  ]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
    onChange(newRows);
  };

  const addRow = () => {
    setRows([...rows, { type: '', quantity: '', wattsPerDevice: '', dayOperationHours: '', nightOperationHours: '' }]);
  };



  return (
    <div className='w-full '>
      <h1 className='rounded-[8px] w-full h-[40px] p-2 bg-secondary bg-opacity-[9%] lg:text-base text-xs text-center font-bold mb-8'>{placeholder}</h1>
      <table className='w-full overflow-x-auto'>
        <thead>
          <tr className='text-start mb-4 lg:text-xl text-[10px]'>
            <th className='text-right'>نوع الجهاز</th>
            <th className='text-right'>العدد</th>
            <th className='text-right'>قدرة الجهاز الواحد بالوات</th>
            <th className='text-right'>عدد ساعات التشغيل اثناء النهار</th>
            <th className='text-right'>عدد ساعات التشغيل اثناء الليل</th>
          </tr>
        </thead>
        <tbody className='w-max '>
          {rows.map((row, index) => (
            <tr key={index} className='my-5 w-full '>
              <td>
                <input className='p-1 lg:px-4 px-1 w-full placeholder:md:text-base placeholder:text-[10px]' placeholder="أدخل نوع الجهاز" type="text" name="type" value={row.type} onChange={(e) => handleInputChange(index, e)} />
              </td>
              <td>
                <input className='p-1 lg:px-4 px-1 w-full placeholder:md:text-base placeholder:text-[10px] ' placeholder="أدخل العدد" type="text" name="quantity" value={row.quantity} onChange={(e) => handleInputChange(index, e)} />
              </td>
              <td>
                <input className='p-1 lg:px-4 px-1 w-full placeholder:md:text-base placeholder:text-[10px] ' placeholder="أدخل قدرة الجهاز الواحد بالوات" type="text" name="wattsPerDevice" value={row.wattsPerDevice} onChange={(e) => handleInputChange(index, e)} />
              </td>
              <td>
                <input className='p-1 lg:px-4 px-1 w-full placeholder:md:text-base placeholder:text-[10px] ' placeholder="أدخل عدد ساعات التشغيل اثناء النهار" type="text" name="dayOperationHours" value={row.dayOperationHours} onChange={(e) => handleInputChange(index, e)} />
              </td>
              <td>
                <input className='p-1 lg:px-4 px-1 w-full placeholder:md:text-base placeholder:text-[10px] ' placeholder="أدخل عدد ساعات التشغيل اثناء الليل" type="text" name="nightOperationHours" value={row.nightOperationHours} onChange={(e) => handleInputChange(index, e)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div onClick={addRow} className='p-2 rounded-[8px] bg-secondary bg-opacity-[9%] my-5 w-max cursor-pointer'>{t("أضافة عنصر")}</div>


    </div>
  );
};


const Message = ({ placeholder, onSend }) => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className=' my-2 mx-auto  text-center '>{placeholder}</h1>
      <button className='p-2 px-20 rounded-[8px] bg-secondary bg-opacity-[9%] my-5 block mx-auto'>{t("ارسال")}</button>
    </>
  )
}