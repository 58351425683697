
import React , { useEffect, useState } from 'react'
import { TiArrowLeftThick } from 'react-icons/ti'
import { Link } from 'react-router-dom'
import GetMethod from '../../../functions/getMethod'
import { useTranslation } from 'react-i18next'

export default function ProjectsSection7() {
  const { t } = useTranslation()
  const [projects, setProjects] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/projects")

      if (response?.status === 200) {
        setProjects(response.data?.data)
      } 
    }
    fetchData()
  }, [])
  return (
    <section className=' mt-20 '>
        <h1 className='text-4xl font-bold  text-center '>{t("مشاريعنا")}</h1>

        <div className=' flex flex-wrap items-center justify-center  mx-auto lg:w-[80%]  w-full mt-10'>
          {
            projects[0] && projects[0]?.id != undefined ?
              projects?.slice(0, 4).map((item, index) => (
                <ProjectCard key={index} id={item?.id} title={item?.name} text={item?.description} img={item?.image_url} />
              )) :
              <>
                <ProjectCard id={1} title={"جامعة"} text={"مشروع الجامعة 1"} img={'/assets/home/ProjectsSection7/img-1.jpg'} />
                <ProjectCard id={1} title={"جامعة"} text={"مشروع الجامعة 2"} img={'/assets/home/ProjectsSection7/img-2.jpg'} />
                <ProjectCard id={1} title={"جامعة"} text={"مشروع الجامعة 3"} img={'/assets/home/ProjectsSection7/img-3.jpg'} />
                <ProjectCard id={1} title={"جامعة"} text={"مشروع الجامعة 4"} img={'/assets/home/ProjectsSection7/img-4.jpg'} />
              </>
          }
        </div>

        <Link to={'/projects'} className='flex items-center justify-center bg-primary rounded-2xl p-4 w-[200px]  text-white lg:my-10 my-5 mx-auto'>
          <h1>{t("المزيد من المشاريع")}</h1>
        </Link>

      </section>
  )
}



const ProjectCard = ({ id, title, text, img }) => {
  const { t } = useTranslation()

  return (
    <div data-aos="fade-up"  className='relative w-[45%] m-2 lg:h-[480px] h-[280px] bg-secondary rounded-lg group group'>
      <div className='absolute bottom-0 left-0  w-full  transition-all h-full bg-black bg-opacity-50 rounded-lg'></div>
      <img src={img} className='w-full h-full object-cover rounded-lg' alt="" />
      <div className='absolute mx-auto w-[97%] h-[30%] rounded-md rounded-r-none shadow-lg flex items-center justify-between bottom-10  text-white p-4 align-bottom  '>
        <div className='lg:w-1/2'>
          <h1 className='lg:text-3xl text-lg font-bold  break-words'>{(title.split(80).includes(' ') ? title.split(' ').slice(0, 3).join(' ') : title.slice(0, 15)) }</h1>
          <p className='lg:text-2xl' style={{overflowWrap: 'break-word'}}>{(text.split(80).includes(' ') ? text.split(' ').slice(0, 3).join(' ') : text.slice(0, 20))}...</p>
        </div>
        <Link to={`/projects/${id}`} className='bg-primary rounded duration-300 p-3 lg:h-14 h-10 lg:w-14 w-10 flex justify-center items-center group-hover:translate-x-3 transition-all '>
          <TiArrowLeftThick color='white'  />
        </Link>
      </div>
    </div>
  )
}