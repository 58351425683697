import React from 'react'
import '../css/loader.css'

export default function Loader() {
  return (
    <div className='bg-white h-full w-full fixed top-0 left-0 z-[9999] flex items-center justify-center'>

    <span class="loader"></span>
    </div>
  )
}
