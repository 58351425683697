import React from 'react'
import Hero from './components/Hero'
import VideoSection from './components/VideoSection'
import Section3 from './components/Section3'
import Section4 from './components/Section4';
import Section5 from './components/Section5';
import Section6 from './components/Section6';
import ProjectsSection7 from './components/ProjectsSection7';
import Section8 from './components/Section8';
import { IoStar } from 'react-icons/io5';
import Section9 from './components/Section9';
import { Link } from 'react-router-dom';
import Section10 from './components/Section10';
import Section4_5 from './components/Section4_5';

export default function HomePage() {



  return (
    <main className=''>
      <Hero />
      <VideoSection />
      <Section3 />
      <Section4 />
      <Section4_5 />
      <Section5 />
      <Section6 />
      <ProjectsSection7 />
      {/* <Section8 /> */}
      <Section9 />
      <Section10 />

    </main>
  )
}









