import React, { useEffect, useState } from 'react'
import Carousel from '../../../components/Carousel'
import GetMethod from '../../../functions/getMethod'
import { useTranslation } from 'react-i18next'

export default function Hero() {
  const [sliders, setSliders] = React.useState([])
  const [counters, setCounters] = React.useState({})
  const [loading,setLoading] =  React.useState(true)
  const {t} = useTranslation()
  const LANG = localStorage.getItem('LANGUAGE') === "en"
  



  useEffect(() => {
    const fetchData = async () => {
      const res = await GetMethod("/home_sliders")
      const response2 = await GetMethod("/counters")
      if (res?.status == 200 || res?.status == 201) {
        setSliders(res?.data?.data)
        setLoading(false)
      }
      if (response2?.status === 200) {
        setCounters(response2.data?.data)
      } 
    }
    fetchData()
  }, [])


  return (
    <>

      <section className='relative h-[750px] '>

        

        {sliders.length > 0 ?

          <Carousel customStyles={"gap-5  w-full"} carouselClass={'hero'} dots={false} autoPlay={true} loop={true} loading={loading} >
            {
              sliders?.map((item, index) => (
                <div key={index} className='carsousel-cell  w-full h-[840px]  text-white flex items-center  justify-center gap-2'>
                  <div className=' bg-black bg-opacity-60 h-full  w-[100%] flex flex-col items-center justify-center gap-2'>
                    {
                      item.video ?  
                      <video className='h-[840px] w-full object-cover  z-[-1] absolute top-0 left-0' src={item.video} autoPlay loop={true} muted  />
                      : 
                      <img className='h-[840px] w-full object-cover  z-[-1] absolute top-0 left-0' src={item.image} alt="" />
                    }
                    <h1 className='bg-secondary bg-opacity-60 text-white text-center rounded-full p-4 px-20 lg:text-2xl'>{item.main_title}</h1>
                    <p className='lg:text-3xl font-bold text-center'>{item.sub_title}</p>
                  </div>
                </div>
              ))
            }
          </Carousel>

          :

          <div  className='carsousel-cell  w-full h-[840px]  text-white flex items-center  justify-center gap-2'>
                  <div className=' bg-black bg-opacity-60 h-full  w-[100%] flex flex-col items-center justify-center gap-2'>
                    <img className='h-[840px] w-full object-cover  z-[-1] absolute top-0 left-0' src={"/assets/home/hero/p1.svg"} alt="" />
                    <h1 className='bg-secondary bg-opacity-60 text-white text-center rounded-full p-4 px-20 lg:text-2xl'>{t('تميزنا بالكفاءة والاحترافية')}</h1>
                    <p className='lg:text-3xl font-bold text-center'>{t('نقوم ببناء كل مشروع بأعلى معايير الجودة')}</p>
                  </div>
                </div>
          

        }


      </section>


      <section className='  lg:w-[95%] w-[98%] ms-auto xl:h-[240px] lg:h-[160px] h-[100px] flex items-center justify-end relative '>
        <img className={`w-full h-full  object-cover  absolute left-0 top-0 z-[1] ${LANG ? "rotate-180" : ""}`} src="/assets/home/hero/Polygon1.svg" alt="" />

        <div className='z-[1]  flex items-center justify-end  lg:p-20 md:p-4 p-1  w-full h-full lg:gap-8 gap-1 text-white md:scale-100 scale-90'>
          {
            counters[0] ?
              <>
                <Card title={counters[0]?.title} number={counters[0]?.number} image="/assets/home/hero/v4.svg" isLine={false} />
                <Card title={counters[1]?.title} number={counters[1]?.number} image="/assets/home/hero/v3.svg" />
                <Card title={counters[2]?.title} number={counters[2]?.number} image="/assets/home/hero/v2.svg" />
                <Card title={counters[3]?.title} number={counters[3]?.number} image="/assets/home/hero/v1.svg" />
              </>
              :
              <>
                <Card title="المشاريع" number={30} image="/assets/home/hero/v4.svg" isLine={false} />
                <Card title="الجوائز" number={155} image="/assets/home/hero/v3.svg" />
                <Card title="أعمال العملاء" number={20} image="/assets/home/hero/v2.svg" />
                <Card title="العملاء" number={10} image="/assets/home/hero/v1.svg" />
              </>
          }

        </div>
      </section>



    </>

  )
}



function Card({ title, number, image, isLine = true }) {
  const { t } = useTranslation();
  return (
    <div className=' xl:w-[320px] lg:w-[180px] md:w-[160px] w-[150px]  h-full  lg:text-4xl flex items-center  lg:gap-6 gap-1'>
      {isLine && <span className='h-full lg:w-1 w-[1px] bg-white'></span>}
      <h1 className='mb-4 '>{t(title)}</h1>

      <div className='w-14 '>
        <img className='' src={image} alt="" />
        <p>{number}+</p>
      </div>

    </div>
  )

}