import React, { useEffect } from 'react';
import GetMethod from '../../../functions/getMethod';
import { FaPlay } from "react-icons/fa";
import VideoComponent from '../../../components/VideoComponent';


export default function VideoSection() {
  const [video, setVideo] = React.useState({})
  useEffect(() => {
    const fetchData = async () => {
      let res = await GetMethod("/videos")
      if (res?.status == 200 || res?.status == 201) {
        setVideo(res?.data?.data[0])
      }
    }
    fetchData()
  }, [])



  




  return (
    <div className="lg:w-[60%] w-[90%] h-[300px]  lg:h-[500px] mx-auto my-16 rounded-lg overflow-hidden shadow-lg">
    <VideoComponent video_url={video?.video_link} cover_url={video?.cover_image} title={video?.title} /> 
    </div>
  );
}
