import React, { useEffect, useState } from 'react'
import Title from '../../components/Title'
import { Link, useParams } from 'react-router-dom'
import GetMethod from '../../functions/getMethod'
import { useTranslation } from 'react-i18next'
import VideoComponent from '../../components/VideoComponent'
export default function PostPage() {
  const { id } = useParams()
  const [post, setPost] = useState({})
  const { t } = useTranslation()
  const LANG = localStorage.getItem('LANGUAGE') || 'ar'
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod(`/posts/${id}`)
      if (response?.status === 200 || response?.status === 201) {
        setPost(response.data?.data)

      } else {
        setPost(null)
      }
    }
    fetchData()
  }, [])
  return (
    <>
      <div className='w-full relative h-full'>

        {
          !post ?
            <h1>{t('لم يتم العثور على البيانات')}</h1>
            : (
              <>
                <div className='relative w-[90%] mx-auto bg-primary bg-opacity-[11%]  rounded-[55px]'>

                  <div className={`absolute top-[-10px] ${LANG === 'en' ? 'right-[-25px] rotate-90' : 'left-[-10px] '} lg:w-[200px] w-[80px] lg:h-[170px] h-[90px] `}>
                    <img src="/assets/Services/Polygon 2.png" className='w-full h-full' alt="" />
                  </div>
                  <div className='w-[60%] mx-auto mt-20 mb-10 py-5 '>
                    {
                      post?.title && <h1 className='text-center text-xl font-bold ' style={{ overflowWrap: 'break-word' }}>{post?.title}</h1>
                    }
                  </div>

                  <div className='w-[95%] mx-auto lg:text-xl text-start pb-6'>
                    <p style={{ overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: post?.description?.replace(/\n/g, "<br>") }}></p>
                  </div>
                </div>

            
                {
                  post?.video_link ? <div className='lg:w-[50%] w-[80%] lg:h-[500px] h-[300px] mx-auto object-cover my-10' >
                    <VideoComponent video_url={post?.video_link} />
                  </div>
                    :
                    (
                      <img src={post?.image ? post?.image : "/assets/Services/p1.jpg"} className='w-[90%] object-cover lg:h-[600px] h-[360px] rounded-[55px] mx-auto mt-20 ' alt="" />

                    )
                }


                <div className='w-full mt-20 mb-20 flex flex-col items-center justify-center '>
                  <Link to={`/blog`} className='bg-primary text-center text-xl text-white font-bold w-[200px] h-[50px] flex justify-center items-center'>
                    {t("باقي المدونات")}
                  </Link>
                </div>
              </>

            )
        }


      </div>
    </>
  )
}

