import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Title from '../../components/Title';
import VideoSection from './../Home/components/VideoSection';
import { useTranslation } from 'react-i18next';
import GetMethod from '../../functions/getMethod';
import VideoComponent from '../../components/VideoComponent';
import Carousel from './../../components/Carousel';

export default function ProjectDetailsPage() {
  const { id } = useParams();
  const { t } = useTranslation()
  const [project, setProject] = useState({})
  const [projectImages, setProjectImages] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod(`/projects/${id}`)

      if (response?.status === 200 || response?.status === 201) {
        setProject(response.data?.data)

        const imagesRes = await GetMethod(`/ProjectImage`)

        if (imagesRes?.status === 200 || imagesRes?.status === 201) {
          setProjectImages(imagesRes.data?.data.filter(image => image.project_id == id))

          setLoading(false)
        }
      }
    }
    fetchData()


  }, [])
  return (
    <>
      <div className='w-full mt-10 mb-10'>
        <Title center={true} title="اعرف اكتر عن المشروع" />
      </div>
      <div className='w-[98%] px-5 mx-auto bg-secondary rounded-b-[40px] h-[80px] text-white xl:text-4xl xltext-3xl font-bold flex justify-center items-center break-words'>
        <h1 className='break-words w-[80%] text-center'>{project?.name}</h1>
      </div>
      <div className='relative flex xl:w-[80%]  w-[90%] min-h-[600px] mx-auto bg-red-500 z-1'>
        <div className='w-full h-full bg-black bg-opacity-70 absolute z-[2]'>

        </div>
        <img src={project?.image_url} className='absolute w-full h-full bg-opacity-20 object-cover z-[1]' alt="" />
        <p className='w-full lg:text-xl z-[5] text-white p-4  h-full' style={{ overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: project?.description?.replace(/\n/g, "<br>") }}></p>

      </div>

      <div className='w-full mt-20 mb-20 flex flex-col items-center justify-center  '>
        <h1 className='xltext-3xl text-2xl text-center font-bold'>{t("شاهد تفاصيل المشروع")}</h1>


        {
          projectImages?.length > 0 ? (
            
        <Carousel customStyles={"gap-5 my-10  w-[90%] md:w-[70%] lg:w-[50%]"} carouselClass={'hero'} dots={false} autoPlay={true} loop={true} loading={loading} >
        {
          project?.video_link && project?.video_link.includes("https") ?
           (<div className='w-full h-full mx-auto object-cover '>
           <VideoComponent video_url={project?.video_link}  />
           </div>)
           :
           (<>
           </>)
        }
            {
              projectImages?.map((item, index) => (
                <div key={index} className='carsousel-cell  w-full h-[440px]  text-white flex items-center  justify-center gap-2 p-5 '>
                      <img className='h-[440px] w-full object-cover  z-[-1] absolute top-0 left-0 rounded-lg' src={item.image_path} alt="" />
                </div>
              ))
            }
          </Carousel>
          )
          :(
              project?.video_link && project?.video_link.includes("https") ?
               (<div className='w-[50%] h-[500px] mx-auto object-cover my-10'>
               <VideoComponent video_url={project?.video_link}  />
               </div>)
               :
               (<>
               </>)
          )
        }

        

        <Link to={`/projects`} className='bg-secondary text-xl text-white font-bold w-[200px] h-[50px] flex justify-center items-center'>
          {t("المزيد من المشاريع")}
        </Link>
      </div>
    </>
  )
}
