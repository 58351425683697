import React, { useEffect, useState } from 'react';
import GetMethod from './../../../functions/getMethod';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Section2({postsList = []}) {
  const [categories, setCategories] = useState([]);
  const [posts, setPosts] = useState([...postsList]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6); // Number of posts per page
  const [categoriesPerPage] = useState(4); // Number of categories per page
  const [currentCategory, setCurrentCategory] = useState(null);
  const [displayedCategories, setDisplayedCategories] = useState([]);
  const { t } = useTranslation();
  const LANG = localStorage.getItem('LANGUAGE') || 'ar';


  useEffect(() => {
    setPosts([...postsList]);
  },[postsList])
  useEffect(() => {

    const fetchData = async () => {
      const response = await GetMethod("/categories");
    
      if (response?.status === 200) {
        setCategories(response.data?.data);
      }
      
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Calculate the indexes for categories pagination
    const indexOfLastCategory = currentPage * categoriesPerPage;
    const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
    const currentCategories = categories
    setDisplayedCategories(currentCategories);
  }, [categories, currentPage, categoriesPerPage]);

  // Get current posts based on pagination and category filter
  const filteredPosts = currentCategory
    ? posts.filter(post => post.category?.name === currentCategory)
    : posts;

  // Pagination for posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
    window.scroll({
      behavior: 'smooth',
      top: 900
    });
  };

  const handleCategoryClick = (categoryId) => {
    setCurrentCategory(categoryId);
    setCurrentPage(1); // Reset page number when category changes
  };

  return (
    <>
      <div className='w-full my-20 flex flex-col'>
        <section className='w-full p-2 mx-auto overflow-x-auto flex flex-row xl:gap-12 gap-4 mb-20'>
          <HeaderTitle
            title="جميع المقالات"
            onClick={() => handleCategoryClick(null)}
            active={!currentCategory}
          />
          {displayedCategories?.map((category) => (
            <HeaderTitle
              key={category.id}
              title={category.name}
              onClick={() => handleCategoryClick(category.name)}
              active={category.name === currentCategory}
            />
          ))}
        </section>
        {currentPosts.length > 0 ?
          <section className={`w-[90%] mx-auto xl:grid xl:grid-cols-3 gap-y-4 flex ${LANG != "ar" ? "flex-row-reverse" : "flex-row"} flex-wrap mt-10 mb-10 items-center justify-center`}>
            {currentPosts.map((post) => (
              <Card key={post.id} id={post.id} img={post?.image} text={post?.description} title={post?.title} />
            ))}
          </section>
          :
          (
            <>
              <h1 className='text-3xl text-center  font-bold my-10'>{t("لا يوجد مقالات")}</h1>
            </>
          )
        }
        <Pagination
          itemsPerPage={postsPerPage}
          totalItems={filteredPosts.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </>
  );
}

const HeaderTitle = ({ title, onClick, active }) => {
  const { t } = useTranslation();
  return (
    <>
      <h1
        onClick={onClick}
        className={` p-2 border-2 cursor-pointer text-nowrap border-secondary font-bold text-sm text-secondary hover:text-white foucs:text-white active:text-white text-center focus:bg-secondary active:bg-secondary hover:bg-secondary rounded-full ${active ? 'bg-secondary text-white' : ''}`}
      >
        {t(title)}
      </h1>
    </>
  );
};

const Card = ({ id, img, text, title }) => {
  return (
    <>
      <Link to={`/post/${id}`} className='relative flex flex-col m-5 xl:w-[400px] lg:w-[300px] w-[250px] h-[300px] '>
        <img src={img} className='w-full h-full object-cover rounded-[30px]' alt="" />
        <section className='absolute bottom-0 left-0 bg-[#B2E8F2] rounded-[30px] w-full   p-6 flex flex-col justify-center items-start'>
          <h1 className='lg:text-xl font-bold lg:py-2 w-full'  style={{overflowWrap: 'break-word'}}>{(title.slice(0,50).includes(' ') ? title.split(' ').slice(0, 3).join(' ')  : title.slice(0, 30) + ' ...')}</h1>
          <p className='lg:text-lg text-sm w-full' style={{overflowWrap: 'break-word'}}>{(text.slice(0,50).includes(' ') ? text.split(' ').slice(0, 8).join(' ')  : text.slice(0, 30) + ' ...')}</p>
        </section>
      </Link>
    </>
  );
};

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className='my-4'>
      <ul className='flex justify-center'>
        {pageNumbers.map((number) => (
          <li key={number}>
            <button
              onClick={() => paginate(number)}
              className={`${currentPage === number ? 'bg-secondary text-white' : ''
                } font-bold py-2 px-4 mx-1 rounded-full hover:bg-secondary border-2 border-secondary hover:text-white`}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Section2;
