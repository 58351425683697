import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import GetMethod from '../../../functions/getMethod'

export default function Section5() {
  const { t } = useTranslation()

  const [services, setServices] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/services")

      if (response?.status == 200) {
        setServices(response.data?.data)
      } 
    }
    fetchData()
  }, [])
  return (
    <section className='mt-20 '>
      <h1 className='text-4xl font-bold  text-center '>{t("الخدمات التي نقدمها")}</h1>


      <div className='flex   items-center  justify-center mt-10 gap-3'>
        {
          services[0] && services[0]?.id != undefined ?
            services?.slice(0, 3).map((item, index) => (
              <Card key={index} link={`/services/${item?.id}`} title={item?.name} description={item?.description} image={item?.image_url} />
            )) :
            <>
              <Card link={"/services/1"} title={"استصلاح الأراضي الصحراوية في واحة سيوة"} description={"تعتبر واحة سيوة إحدي أهم المراكز السياحية في مصر سواء كانت السياحة ترفيهية أو إستشفائية..."} image="/assets/home/section5/p1.jpg" />
              <Card link={"/services/1"} title={"وحدة الامداد بالطاقة أثناء انقطاع الكهرباء"} description={"يعمل جهاز إمداد الطاقة اللامنقطعة علي تخزين الطاقة الكهربية عن طريق الشحن التلقائي..."} image="/assets/home/section5/p2.jpg" />
              <Card link={"/services/1"} title={"أنظمة المنزل الذكي"} description={"يُعَدُّ نظام المنزل الذكي أحد تلك التطبيقات البارزة، حيث يمكنه التحكم الآلي بوظائف المنزل..."} image="/assets/home/section5/p3.jpg" />
            </>
        }
      </div>



      <Link to={'/services'} className='flex items-center justify-center bg-primary rounded-2xl p-4 w-[200px]  text-white lg:my-10 my-5 mx-auto'>
        <h1>{t("المزيد من الخدمات")}</h1>
      </Link>

    </section>
  )
}


function Card({ title, description, image, link }) {
  const { t } = useTranslation()
  return (
    <div data-aos="fade-up" className='lg:w-[480px] w-[225px] relative  lg:h-[530px] h-[330px]  gap-6 bg-[#E9E9E9] bg-opacity-[33%] group'>

      <div className=" hidden group-hover:flex bg-black bg-opacity-50 w-full h-full absolute top-0 left-0 z-[1] flex-col gap-3  items-center justify-center text-white">

        <h1 className='lg:text-xl font-bold text-center'>{(title.split(50).includes(' ') ? title.split(' ').slice(0, 6).join(' ') : title.slice(0, 40))}</h1>
        <p className='text-center lg:text-base  text-sm'>{(description.split(50).includes(' ') ? description.split(' ').slice(0, 6).join(' ') : description.slice(0, 40))}...</p>

        <div className='text-black bg-primary  rounded-xl w-20 h-10  flex items-center justify-center text-center mx-auto' ><Link className='p-2 text-center' to={link}>{t("المزيد")}</Link></div>
      </div>

      <div className=' w-full lg:h-[60%] h-[45%]'>
        <img className=' h-full w-full object-cover drop-shadow' src={image} alt="" />

      </div>

      <div className=' w-full lg:h-[40%] h-[55%] flex flex-col items-center  justify-between py-1'>
        <h1 className='lg:text-xl text-sm font-bold text-center'>{(title.split(50).includes(' ') ? title.split(' ').slice(0, 6).join(' ') : title.slice(0, 40))}</h1>
        <p className='text-center lg:text-base  text-xs break-words w-full'>{(description.split(50).includes(' ') ? description.split(' ').slice(0, 6).join(' ') : description.slice(0, 40))}...</p>

        <div className='group-hover:hidden text-black bg-primary  rounded-xl lg:w-20 w-16 lg:h-10 h-8  flex items-center justify-center text-center mx-auto mb-1' ><Link className='p-2 text-center' to={link}>{t("المزيد")}</Link></div>
        <div className='group-hover:block hidden lg:w-20 w-16 lg:h-10 h-8  '></div>
      </div>
    </div>
  )

}
