
import React from 'react'
import { useTranslation } from 'react-i18next'

function Section4() {
  const {t} = useTranslation()
  const Titles = [
    {title : "201008309836+ | 201289241157+" , icon : "/assets/AboutUs/Section4/Vector.svg"},
    {title : "info@gomaacompany.com" , icon : "/assets/AboutUs/Section4/Vector-1.svg"},
    {title : "مطروح - سيوة -السوق - أمام فندق النخيل" , icon : "/assets/ContactUs/Vector (2).png"},
    {title : "الجيزة - 6 أكتوبر - محور بوليفار - أمام كمبوند جراند هايتس",icon : "/assets/ContactUs/Vector (2).png"},
  ]
  
  return (
    <>
    <div className='w-full flex justify-center items-center my-10 gap-3'>
      <section className='xl:w-[30%] min-h-[200px] w-fit bg-primary flex flex-col rounded-[20px] p-4'>
        {
          Titles?.map((item , index) =>{
            return (
              <div key={index} className='w-full flex p-2'>
                <img className=' object-contain' src={item.icon} alt="" />
                <h1 className='w-full text-white text-center'>
                  {t(item.title)}
                </h1>
              </div>
            )
          })
        }
      </section>
      <section className='w-[50%]  h-fit flex flex-col py-6 px-8 shadow-xl rounded-lg '>
        <h1 className='text-primary text-xl font-bold py-2'>{t("تواصل معنا")}</h1>
        <h1 className='font-bold xl:text-xl text-sm '>{t("اتصل بنا اليوم لمناقشة احتياجاتك من الطاقة واتخاذ الخطوة الأولى نحو غد مستدام")}</h1>
      </section>
    </div>
    </>
  )
}

export default Section4