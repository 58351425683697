
import React from 'react'
import { useTranslation } from 'react-i18next'

function Section1({data }) {
  const {t} = useTranslation()

  const handelScroll200px = () => {
    window.scrollTo({ top: 800, behavior: 'smooth' });
  }
  return (
    <>
      <div className='relative lg:w-[80%] w-[90%] mx-auto   lg:h-[600px] h-[400px]  rounded-tr-[100px] rounded-bl-[100px] flex flex-row px-12 py-4 from-[#F7CB15] bg-gradient-to-b to-black my-10'>
        <div className=' h-full flex flex-col items-center px-4'>
          <h1 className='lg:text-4xl text-xl font-bold text-white py-2'>{t("من نحن")}</h1>
          {/* <img src={data.main_image ? data.main_image : "/assets/AboutUs/Section1/Rectangle 66.png"} className='lg:w-[356px] w-[156px] lg:h-[480px] h-[250px] mt-4 mb-4 object-cover' alt="" /> */}
        </div>
        <div className=' h-full flex items-start'>
          {/* <img src={data.secondary_image ? data.secondary_image : "/assets/AboutUs/Section1/Rectangle 67.png"} className='lg:w-[303px] w-[203px]  lg:h-[330px] h-[230px] mt-4 object-cover' alt="" /> */}
        </div>
        <div className='absolute w-[90%] lg:left-20 left-5 bottom-1 flex flex-col items-end'>
          <TextCard text={data.description ? data.description : "كما نقدم خدمات الستشارية هندسية متخصصة لتطوير وتنفيذ أنظمة طاقة هجينة مبتكرة تجمع بين الطاقةالشمسية ومصادر الطاقة التقليدية"} />
          <button onClick={handelScroll200px} className='bg-primary lg:w-[20%] w-[120px] lg:py-4 p-2 rounded-lg font-bold text-xl text-white'>
            {t("إقرأ المزيد")}
          </button>
        </div>
      </div>
    </>
  )
}

const TextCard = ({ text }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full lg:text-lg  sm:text-xs text-[8px] font-bold text-black bg-white bg-opacity-50 py-4 px-3 text-center my-4">
      <p style={{ wordWrap: 'break-word' }}>{t(text)}</p>
    </div>
  );
};

export default Section1