import React, { useEffect, useState } from 'react';
import Title from '../../components/Title';
import { Link } from 'react-router-dom';
import { TiArrowLeftThick } from 'react-icons/ti';
import GetMethod from '../../functions/getMethod';
import Pagination from '../Services/components/Pagination';
import { useTranslation } from 'react-i18next';

export default function ProjectsPage() {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/projects");

      if (response?.status === 200) {
        setProjects(response.data?.data);
      }
    };
    fetchData();
  }, []);

  const PAGE_SIZE = 4;
  const totalPages = Math.ceil(projects.length / PAGE_SIZE);

  useEffect(() => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const filtered = projects.slice(startIndex, endIndex);
    setFilteredProjects(filtered);
  }, [projects, currentPage]);

  return (
    <>
      <div className='w-full'>
        <Title title="مشاريعنا" center={true} />

        <div className='lg:w-[80%] flex flex-wrap justify-center items-center  mx-auto mt-10'>
          {filteredProjects.length > 0 ? (
            filteredProjects.map((item, index) => (
              <ProjectCard key={index} id={item?.id} title={item?.name} text={item?.description} img={item?.image_url} />
            ))
          ) : (
            <h1 className='text-2xl font-bold  text-center mt-10'>{t("لا يوجد مشاريع")}</h1>
          )}
        </div>

        <Pagination totalPages={totalPages} onPageChange={handlePageChange} />
      </div>
    </>
  );
}

const ProjectCard = ({ id, title, text, img }) => {
  return (
    <div className='relative w-[45%] m-2 xl:h-[480px] lg:h-[280px]  h-[250px]  bg-secondary rounded-lg group group'>
      <div className='absolute bottom-0 left-0  w-full  transition-all h-full bg-black bg-opacity-50 rounded-lg'></div>
      <img src={img} className='w-full h-full object-cover rounded-lg' alt="" />
      <div className='absolute mx-auto w-[97%] h-[30%] rounded-md rounded-r-none shadow-lg flex items-center justify-between bottom-10  text-white p-4 align-bottom  '>
        <div className='lg:w-1/2 w-[95%]' >
          <h1 className='lg:text-3xl text-2xl font-bold '>{(title.split(50).includes(' ') ? title.split(' ').slice(0, 3).join(' ') : title.slice(0, 12))}</h1>
          <p className='lg:text-2xl' style={{overflowWrap: 'break-word'}}>{(text.split(50).includes(' ') ? text.split(' ').slice(0, 3).join(' ') : text.slice(0, 30))} ...</p>
        </div>
        <Link to={`/projects/${id}`} className='bg-primary mt-5 rounded duration-300 p-3 lg:h-14 h-10 lg:w-14 w-10 flex justify-center items-center group-hover:translate-x-3 transition-all '>
          <TiArrowLeftThick color='white' />
        </Link>
      </div>
    </div>
  );
};
