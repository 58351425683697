import React, { useEffect, useState } from 'react'
import Carousel from '../../../components/Carousel'
import { IoStar } from 'react-icons/io5'
import { Link } from 'react-router-dom'

import GetMethod from '../../../functions/getMethod'
import { useTranslation } from 'react-i18next'

export default function Section9() {
  const { t } = useTranslation()
  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/reviews")
      if (response?.status == 200 || response?.status == 201) {
        setReviews(response?.data?.data)
        setLoading(false)
      }
    }
    fetchData()
  }, [])
  return (
    <section className=' mt-20'>
      <h1 className='text-4xl font-bold  text-center '>{t("ماذا يقول عملاؤنا")}</h1>


      <div className='flex items-center xl:gap-5 gap-1 mt-10 w-[90%] mx-auto'>

        <Carousel customStyles={"gap-5 h-full w-full  relative"} autoPlay={true} carouselClass="section9" loading={loading}>
          {
            reviews.length > 0 &&

            (reviews?.map((review, index) => (
              <Card key={index} id={review?.id} title={review?.title} description={review?.description} name={review?.client_name} stars={review?.stars} image={review?.image_url} date={review?.date} />
            ))
            )
          }

        </Carousel>

      </div>

    </section>
  )
}



function Card({ id, stars, title, description, name, date, image }) {
  const filledStars = stars >= 0 && stars <= 5 ? stars : 0;

  // Generate an array of stars with correct classes
  const starIcons = Array.from({ length: 5 }, (_, index) => (
    <div key={index}>
      <IoStar className={index < filledStars ? 'text-primary' : 'text-white'} />
    </div>
  ));

  return (
    <Link to={`/comment/${id}`} className=' carousel-cell mx-10 m-5 border border-primary xl:w-[600px] lg:w-[400px] w-[360px]  lg:h-[300px] h-[200px]  ' style={{ padding: '20px' }}>
      {/* <div className=' xl:w-[600px] w-[320px]  h-[150px] ' ></div> */}
      <div className='xl:w-[600px] w-[360px]   bg-[#FEFAE8] rounded-[33px] absolute top-[-20px]  z-[2] lg:p-9 p-3'>
        <h1 className='lg:text-2xl font-bold'>{title}</h1>
        <p className='lg:text-lg  text-sm text-center my-10'>{description}</p>
        <hr className='w-[80%] mx-auto' />

        <div className='flex items-center justify-between lg:mt-5 mt-1'>

          <div className='flex items-center  justify-center gap-2'>
            <img className='lg:w-16 w-12 lg:h-16 h-12 rounded-full bg-[#B4B4B4]' src={image ? image :"/assets/user.svg"} alt="" />
            <div className='font-bold'>
              <h1 className='lg:text-lg  text-sm'>{name}</h1>
              <p className='lg:text-lg  text-sm'>{date}</p>
            </div>

          </div>

          <div className='flex items-center justify-center bg-black rounded-full lg:w-[180px] w-[120px] lg:h-[40px] h-[30px] gap-2 text-lg'>
            {starIcons}
          </div>

        </div>

        <div>

        </div>

      </div>

    </Link>
  )

}
