import React, { useEffect } from 'react';
import { IoStarSharp } from 'react-icons/io5';
import Title from '../../components/Title';
import VideoSection from '../Home/components/VideoSection';
import GetMethod from '../../functions/getMethod';
import { useParams } from 'react-router-dom';
import VideoComponent from '../../components/VideoComponent';

function CommentDetails() {
  // Calculate the number of filled stars based on the rating
  const [comment, setComment] = React.useState({})
  const filledStars = Math.round(comment?.stars);

  const { id } = useParams()
  useEffect(() => {
    const getData = async () => {
      const response = await GetMethod(`/reviews/${id}`)
      if (response?.status === 200 || response?.status === 201) {
        
        setComment(response?.data?.data)
      }
    }

    getData()
  }, [])


  return (
    <>
      <div className='w-[50%] mx-auto'>
        <Title title={'آراء عملاءنا'} center={true} />
      </div>

      <div className='w-full relative h-full'>
        {/* Triangle */}
        <div className='absolute top-[-30px] xl:left-16 left-3 w-[200px] h-[200px] '>
          <img src='/assets/home/comment/Polygon 2 (1).png' className='w-full h-full' alt='' />
        </div>
        {/* Triangle */}
        {/* Card */}
        <div className='w-[90%] mx-auto bg-secondary bg-opacity-[11%]'>
          <div className='mt-20 mb-10 py-40 px-12'>
            <h1 className='text-xl lg:text-4xl font-bold'>
              {comment?.title}
            </h1>
            <div className='mx-auto lg:text-xl py-8'>
              {comment?.description}
            </div>
            <div className='flex  gap-4 items-center text-lg'>
            <p>{comment?.client_name}</p> - <p>{comment?.date}</p>

            </div>
          </div>
        </div>
        {/* Card */}

        {/* Render star rating */}
        <div className='absolute bg-secondary bottom-[-30px] xl:right-24 right-10 xlw-[500px] w-[300px] xlh-[200px] h-[100px] flex justify-center items-center gap-5'>
          {[...Array(5)].map((_, index) => (
            <IoStarSharp
              key={index}
              className={`xlw-[60px] w-[30px] xlh-[60px] h-[30px] cursor-pointer ${index < filledStars ? 'text-primary' : ''
                }`}
            />
          ))}
        </div>
      </div>

    {
          comment?.video ?
           (<div className='w-[50%] h-[500px] mx-auto object-cover my-10'>
           <VideoComponent  video_url={comment?.video}  />
           </div>)
           :
           (<>
           </>)
        }
    </>
  );
}

export default CommentDetails;
