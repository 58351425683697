
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import GetMethod from '../../../functions/getMethod'

function Section3() {
  const [data, setData] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/why-us")
      if (response?.status == 200 || response?.status == 201) {
        setData(response.data?.data[0])
      }
    }

    fetchData()
  }, [])

  return (
    <>
      <div className='w-[90%] flex flex-col text-center mx-auto'>

        <h1 className='text-black flex text-center w-full justify-center font-bold text-4xl py-12 mt-12'>
          {t("لماذا شركة")}
          <p className='text-secondary px-2'>{t("جمعة؟")}</p>
        </h1>
        <div className='w-full'>
          {
            data?.title?.length > 0
              ? data.title?.map((item, index) => <Card key={index} text={item} />)
              :
              <>
                <Card text={"فريق عمل متمرس هو مجموعة من متخصصين في مجالات مختلفة في مجال تصميم وتطوير وتسويق."} img={"/assets/AboutUs/Section3/Vector.png"} />
                <Card text={"خدمات الستشارية هندسية متخصصة لتطوير وتنفيذ أنظمة طاقة هجينة مبتكرة تجمع بين الطاقة الشمسية ومصادر الطاقة التقليدية"} img={"/assets/AboutUs/Section3/Vector.png"} />
                <Card text={"سنوات عريقة من الخبرة حيث تأسست في عام 2010 م وتخصصت في تصميم وتركيب وصيانة أنظمة الطاقة المنجددة"} img={"/assets/AboutUs/Section3/Vector.png"} />
              </>
          }
        </div>
      </div>
    </>
  )
}

const Card = ({ text, img = "/assets/AboutUs/Section3/Vector.png" }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className='w-full min-h-[120px] flex  my-4'>
        <img src={img} className='bg-primary lg:w-[100px] w-[80px] lg:h-[100px] h-[80px] p-4 rounded-full text-center' alt="" />
        <p className='w-full bg-[#FEFAE8] mfpx-6 py-4 text-center lg:text-xl text-sm lg:font-bold font-semibold flex flex-wrap items-center justify-center'>
          {t(text)}
        </p>
      </div>
    </>
  )
}

export default Section3