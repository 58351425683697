import axios from "axios";

const postMethod = async (endPoint, data) => {
  // const token =  ??
  try {
    const response = await axios.post("https://gomaacompany.com/api/public/api" + endPoint, data);

    if (response?.status == 200 || response?.status == 201) {
      return response      
    }
  } catch (error) {
    console.log(error);
    return error.message
  }
};

export default postMethod;