import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaYoutube, FaWhatsapp, FaPhone } from 'react-icons/fa';
import { IoMail } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import GetMethod from '../functions/getMethod';
import postMethod from './../functions/postMethod';
import Swal from 'sweetalert2';

export default function Footer() {
  const [contactData, setContactData] = useState([]);
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/contact-data")
      if (response?.status === 200) {
        setContactData(response.data?.data)
      }
    }
    fetchData()
  }, [])

  const sendSubscription = async (e) => {
    e.preventDefault()

    Swal.fire({
      title: 'جاري التحميل...',
      html: 'يرجى الانتظار بينما يتم معالجة الطلب .',
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    })
    const res = await postMethod("/subscriptions", { email })    
    if (res?.status == 200 || res?.status == 201) {
      setEmail("")
      Swal.fire({
        icon: 'success',
        title: 'تم الاشتراك بنجاح',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }
  return (
    <footer id='footer'  className='bg-tertiary w-full py-10 text-white mt-10 lg:px-4 px-2'>
      <div className='container mx-auto '>
        <div className='grid grid-cols-3 lg:grid-cols-3 gap-10'>
          <div className='flex flex-col items-center justify-center '>
            <img src="/assets/logo.png" alt="" />
            <div className="flex items-center justify-center gap-4 mt-6">
              <Link target='_blank' to="https://www.facebook.com/profile.php?id=100063806260379&mibextid=JRoKGi"><FaFacebook className="w-8 h-8 lg:w-10 lg:h-10" /></Link>
              <Link target='_blank' to="https://youtube.com/@GOMAACOMPANY?si=MLQLIgQvf0bM08uJ"><FaYoutube className="w-8 h-8 lg:w-10 lg:h-10" /></Link>
              <Link target='_blank' to="https://wa.me/+201008309836"><FaWhatsapp className="w-8 h-8 lg:w-10 lg:h-10" /></Link>
            </div>
          </div>

          <div className='flex flex-col items-center justify-center '>
            <h1 className='font-bold text-sm lg:text-3xl mb-4'>{t("صفحات مهمة")}</h1>
            <div className='flex flex-col gap-2 text-center'>
              <Link to={"/"} className='text-lg lg:text-xl hover:text-primary'>{t("الرئيسية")}</Link>
              <Link to={"/about-us"} className='text-lg lg:text-xl hover:text-primary'>{t("من نحن")}</Link>
              <Link to={"/services"} className='text-lg lg:text-xl hover:text-primary'>{t("الخدمات")}</Link>
              <Link to={"/contact-us"} className='text-lg lg:text-xl hover:text-primary'>{t("تواصل معنا")}</Link>
              <Link to={"/info"} className='text-lg lg:text-xl hover:text-primary'>{t("تنويه")}</Link>
            </div>
          </div>

          <div className='flex flex-col  items-center justify-center'>
            <div className='flex h-10  justify-center items-center gap-2 mb-4'>
              <FaPhone className="text-xl lg:text-4xl" />
              <h1 className='font-bold text-sm lg:text-3xl'>{t("تواصل معنا")}</h1>
            </div>
            
            <div className='flex flex-col gap-2 text-center flex-wrap h-[180px] md:overflow-visible overflow-x-scroll over'>
              {contactData.length > 0 && (
                <>
                  {contactData[0].phone_numbers?.map((phoneNumber, index) => (
                    <h1 key={index}>
                      <Link className='underline hover:text-primary' to={`tel://${phoneNumber}`}>{phoneNumber}</Link>
                    </h1>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>

        <div className='flex items-center lg:justify-between justify-around mt-12'>
          <div>
            <h1 className='font-bold text-lg lg:text-3xl mb-4'>{t("اشترك واحصل على اخر التحديثات")}</h1>
            <form className='flex items-center gap-4' onSubmit={sendSubscription}>
              <input value={email} onChange={(e) => setEmail(e.target.value)}required   className='rounded-full bg-gray-200 lg:px-4 px-2 lg:py-2 py-1 text-black focus:outline-none' type="email" placeholder={t('البريد الإلكتروني')} />
              <button  className='bg-primary text-white lg:px-6 px-3 lg:py-2 py-1 rounded-full hover:bg-primary-dark transition-colors duration-300'>{t("اشتراك")}</button>
            </form>
          </div>
        <div className='flex flex-col gap-1'>
        <div className='flex items-center gap-2 text-xs lg:text-xl'>
            <IoMail />
            <h1>info@gomaacompany.com</h1>
          </div>
          <div className='flex items-center gap-2 text-xs lg:text-xl'>
            <IoMail />
            <h1>business@gomaacompany.com</h1>
          </div>
          <div className='flex items-center gap-2 text-xs lg:text-xl'>
            <IoMail />
            <h1>job@gomaacompany.com</h1>
          </div>
        </div>
        </div>

        <hr className='my-8' />

        <div className='flex items-center justify-center gap-4'>
          <h1 className='lg:text-lg text-sm '>
            <Link className='underline hover:text-primary' to="https://www.facebook.com/people/Digital-Code/100075543259480/?mibextid=ZbWKwL">{t("تم الإنشاء بواسطة")} Digital Code</Link>
          </h1>
          <h1> | </h1>
          <h1 className='text-center lg:text-lg text-sm '>{t("جميع الحقوق محفوظة")} Gomaa © {new Date().getFullYear()}</h1>
        </div>
      </div>
    </footer>
  );
}
