import React from 'react'
import { FaPlay } from 'react-icons/fa';

export default function VideoComponent({video_url,cover_url,title }) {
  const [isVideoPlayed, setIsVideoPlayed] = React.useState(false);



  function playVideo() {
    setIsVideoPlayed(true);
  }


  return (
    <div className="relative w-full h-full">
    <iframe
      className="w-full h-full"
      src={video_url?.replace(/\/view\?usp=drive_link/, '/preview')}
      title="Video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      onPlay={() => setIsVideoPlayed(true)}
    ></iframe>
    {!isVideoPlayed && (cover_url || title) &&  (
      <div className='absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center  w-full  h-[100%] bg-white text-white'>
        <div className='w-full h-full relative '>
          <img className='absolute  w-full h-full object-cover' src={cover_url} alt="" />
          <div className="absolute inset-0 flex items-center justify-center text-white">
            {!isVideoPlayed && (
              <div className='flex flex-col items-center justify-center'>
                {
                  title && <h2 className="lg:text-2xl text-black font-bold text-center z-1 bg-white p-2 rounded">{title}</h2>
                }                
                <button onClick={playVideo} className='bg-secondary duration-300 group-hover:bg-primary   p-2 rounded font-bold text-xl text-white mt-12'>
                  <FaPlay className='text-4xl' />
                </button>
              </div>
            )}
          </div>

        </div>

      </div>
    )}
  </div>
  )
}
