import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import HomePage from '../Pages/Home/HomePage'
import AboutUsPage from './../Pages/AboutUs/AboutUsPage';
import ServicesPage from '../Pages/Services/ServicesPage';
import ProjectsPage from '../Pages/Projects/ProjectsPage';
import BlogPage from '../Pages/Blog/BlogPage';
import ContactUsPage from './../Pages/ContactUs/ContactUsPage';
import ProjectDetails from '../Pages/Projects/ProjectDetailsPage';
import ServiceDetails from '../Pages/Services/ServiceDetailsPage';
import RequestPrice from '../Pages/RequestPrice/RequestPricePage';
import CommentDetails from '../Pages/CommentDetails/CommentDetailsPage';
import LatestNewsDetails from '../Pages/LatestNewsDetails/LatestNewsDetails';
import MoreInfo from '../Pages/MoreInfo/MoreInfo';
import PostPage from '../Pages/Blog/PostPage';

function PublicRouter() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
      </Routes>
      <div className='h-[180px] w-full'></div>

      <Routes>
        <Route path='/home' element={<HomePage />} />
        <Route path='/comment/:id' element={<CommentDetails />} />
        <Route path='/latest-news/:id' element={<LatestNewsDetails />} />
        <Route path='/info' element={<MoreInfo />} />
        <Route path='/about-us' element={<AboutUsPage />} />

        <Route path='/services' element={<ServicesPage />} />
        <Route path='/services/:id' element={<ServiceDetails />} />

        <Route path='/request-price' element={<RequestPrice />} />

        <Route path='/projects' element={<ProjectsPage />} />
        <Route path='/projects/:id' element={<ProjectDetails />} />

        <Route path='/blog' element={<BlogPage />} />
        <Route path='/post/:id' element={<PostPage />} />
        <Route path='/contact-us' element={<ContactUsPage />} />

        <Route path='*' element={<Navigate to="/" />} />
      </Routes>
    </>
  )
}


export default PublicRouter