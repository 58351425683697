import React from 'react'
import { useTranslation } from 'react-i18next'

function Title({ title, coloredTitle, color, moreTitle, center }) {
  const {t} = useTranslation()
  return (
    <>
    <div className={`w-full ${center ? "flex justify-center items-center text-center" : "text-start"} `} >
    <h1 className={`w-full flex ${center ? "justify-center items-center" : "text-start"} text-wrap xl:text-3xl text-2xl font-bold `} >
      {t(title)}
      {
        coloredTitle && <p className={`text-nowrap text-${color} px-2 `}>{t(coloredTitle)}</p>
      }
      {moreTitle? moreTitle : ""}
    </h1>
      </div>
    </>
  )
}

export default Title