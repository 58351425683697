import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import GetMethod from '../../../functions/getMethod';
import { Link } from 'react-router-dom';
function Section2() {
  const { t } = useTranslation()

  const [contactData, setContactData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/contact-data")
      if (response?.status === 200) {
        setContactData(response.data?.data)
      }
    }
    fetchData()
  }, [])
  return (
    <>
      <div className=' w-[95%] flex justify-between items-start  mx-auto mt-32 px-4'>
        <section className='w-[50%] items-center justify-start flex flex-col '>
          <h1 className='xl:text-4xl lg:text-2xl text-xl font-bold mb-4'>{t("يمكنك أيضا زيارة فروعنا")}</h1>
          <div>

          {
            contactData.length > 0 && (
              <>
                {contactData[0].addresses?.map((address, index) => (
                   <Text text={address} img="/assets/ContactUs/Vector (2).png" />
                ))}
              </>
            )
          }
          </div>
        </section>

        <section className='w-[50%] flex   items-center justify-center flex-col'>

          <div className='flex items-center'>
            <img className='h-10 mx-4' src="/assets/ContactUs/Vector.svg" alt="" />
            <h1 className='xl:text-4xl lg:text-2xl text-xl font-bold mb-4 '>
              {t("أو")} <span className='text-primary '>{t("اتصل بنا")}</span> {t("على الأرقام التالية")}
            </h1>

          </div>

          <div className='text-center grid grid-cols-2 gap-3 content-center items-center justify-center  mt-4 '>
            {contactData.length > 0 && (
              <>
                {contactData[0].phone_numbers?.map((phoneNumber, index) => (
                  <h1 className='hover:text-primary transition-all'>
                    <Link to={`tel:${phoneNumber}`} className='text-xl lg:text-2xl font-bold'>{phoneNumber}</Link>
                  </h1>
                ))}
              </>
            )}
          </div>
        </section>
      </div>
    </>
  )
}
const Text = ({ text, img }) => {
  return (
    <div className='   flex items-center my-1 '>
      <img src={img} className='bg-primary p-4 rounded-full' alt="" />
      <h1 className=' text-lg lg:text-2xl font-semibold mx-2'>{text}</h1>
    </div>
  )
}

export default Section2