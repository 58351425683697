import React, { useEffect, useState } from 'react';
import ModalComponent from './Modal';
import { useTranslation } from 'react-i18next';

export default function Profile({ isOpen, setIsOpen }) {
  const [user, setUser] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Fetch user data from localStorage and parse it
    const userData = JSON.parse(localStorage.getItem('user'));
    setUser(userData.user);
  }, []);

  const logOut = () => {
    localStorage.removeItem('user');
    setUser(null);
    setIsOpen(false);
  }

  return (
    <ModalComponent isOpen={isOpen} closeModal={() => setIsOpen(false)}>
      <div className='bg-white rounded-lg p-4 flex flex-col items-center justify-center w-full h-[450px]'>
        {/* You can display other user data here */}
        <img
          src={ user?.image ? user?.image :'https://gomaacompany.com/api/public/images/1714582321_user.jpg'}
          alt={user?.name}
          className='w-24 h-24 rounded-full mt-4'
        />
        {/* Display user name */}
        <h1 className='text-xl font-semibold'>{user?.name}</h1>
        {/* Display user email */}
        <p className='text-gray-600'>{user?.email}</p>
        

        <button onClick={logOut} className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mt-4'>{t("تسجيل الخروج")}</button>
      </div>
    </ModalComponent>
  );
}
