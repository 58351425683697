
import React from 'react'
import { useTranslation } from 'react-i18next'

function Section2({data}) {

  return (
    <>
      <div className='w-[90%] mx-auto flex justify-center items-center flex-col'>
        <SectionDiv title={data?.title_1} img={data.image_1 ? data.image_1 : "/assets/AboutUs/Section2/Rectangle 68.png"} text={data.description_1 ? data.description_1 : "نسعي في أن نكون الشركة الأولي في مجال الطاقة المتجددة وأنظمة المنزل الذكي وأنظمة إمدادات الطاقة اللامنقطعة (UPS) وكل ما يخص الإستصلاح الزراعي للأراضي الصحراوية، سواء في التصميم والتركيب والصيانة وتجارة المستلزمات."} />
        <SectionDiv title={data?.title_2} img={data.image_2 ? data.image_2 : "/assets/AboutUs/Section2/Rectangle 69.png"} text={data.description_2 ? data.description_2 :"المساهمة في الحفاظ علي البيئة والحد من إنبعاثات الكربون عن طريق إستبدال أنظمة الطاقة الملوثة للبينة بأنظمة الطاقة المتجددة النظيفة، وتحقيق الإكتفاء الذاتي من إنتشار الزراعة."}  inverted={true}/>
        <SectionDiv title={data?.title_3} img={data.image_3 ? data.image_3 : "/assets/AboutUs/Section2/Rectangle 68.png"} text={data.description_3 ? data.description_3 : "نسعى في تطوير النشاط وزيادة حجم الأعمال سنويا في جميع المجالات التي تعمل عليها الشركة، كما نسعي في نشر الثقافة والوعي بكل ما يخص أنظمة الطاقة المتجددة وأنظمة المنزل الذكي وأنظمة إمدادات الطاقه اللامنقطعة UPS وكل ما يخص الإستصلاح الزراعي للأراضي الصحراوية من خلال العروض الفنية ودراسات الجدوي الإقتصادية التي تقدم مجانا لكل من يطلبها."} />
      </div>
    </>
  )
}
const SectionDiv = ({ title , title2 , text ,img , inverted }) => {
  const {t} = useTranslation()

  return (
    <>
      <section className={`w-full xl:h-full lg:min-h-[450px] flex flex-row group justify-between items-center ${inverted ? "flex-row-reverse" : ""} `}>
        <div className='w-[50%] flex flex-col '>
          <h1 className='text-black flex font-bold text-4xl py-12 mt-12'>
            {t(title)}
            <p className='text-secondary duration-300 group-hover:text-primary px-2'>{t(title2)}</p>
          </h1>
          <p className=' lg:text-2xl text-sm w-[100%]'>
            {t(text)}
          </p>
          <button className='bg-secondary duration-300 group-hover:bg-primary lg:w-[30%] w-[60%] py-2 rounded-sm font-bold text-xl text-white mt-12 cursor-default'>
          {t("تواصل معنا")}
          </button>
        </div>
        <div className={`w-[50%] flex items-center lg:scale-100 xl:scale-75  h-full  ${inverted ? "ml-12" : "mr-12"} `}>
          <img src={img} className='w-full h-full object-contain' alt="" />
        </div>
      </section>
    </>
  )
}
export default Section2