import React, { useEffect, useState } from 'react';
import Title from './../../components/Title';
import { Link } from 'react-router-dom';
import GetMethod from '../../functions/getMethod';

export default function ServicesPage() {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredServices, setFilteredServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/services");

      if (response?.status === 200 || response?.status === 201) {
        setServices(response.data?.data);
      } 
    };
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = currentPage === 1 ? services.slice(0, 3) : services.slice((currentPage - 1) * 3, currentPage * 3);
    setFilteredServices(filtered);
  }, [services, currentPage]);

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setCurrentPage(page);
  };

  const PAGE_SIZE = 3;
  const totalPages = Math.ceil(services?.length / PAGE_SIZE);

  return (
    <>
      <div className='lg:w-[85%] w-full mx-auto '>
        <Title title="الخدمات التي نقدمها" center={true} />
        <div className='w-[100%] mx-auto flex flex-col'>
          {filteredServices.map((service, index) => (
            <ServiceCard key={index} inverted={index % 2 === 1} title={service.name} text={service.description} img={service.image_url} link={service.id} />
          ))}
        </div>
      </div>
      <Pagination totalPages={totalPages} onPageChange={handlePageChange} currentPage={currentPage} />
    </>
  );
}

const ServiceCard = ({ title, text, img, inverted, link }) => {
  const colorTitleWords = (title) => {
    const words = title?.split(' ');
    return (
      <h1 className='text-black font-bold lg:text-2xl text-lg my-5 w-full text-wrap text-center flex items-center justify-center flex-wrap  '>
        {words ? words?.map((word, index) => {
          if (index === 0 || index === 2) {
            return (
              <span key={index}>
                <span className={inverted ? 'text-primary' : 'text-secondary'}>{word}</span>
                &nbsp;
              </span>
            );
          } else {
            return (
              <>
                {word}&nbsp;
              </>
            ) 
          }
        }) : <h1>{title}</h1>}
      </h1>
    );
  };

  return (
    <Link to={`/services/${link}`} className={`lg:w-[85%] w-[90%] mx-auto lg:h-[450px] h-[280px] overflow-hidden rounded-[56px] flex ${inverted ? "flex-row-reverse" : "flex-row"} items-center bg-secondary bg-opacity-[11%] hover:bg-primary hover:bg-opacity-[11%] justify-between group duration-300 my-8 `}>
      <div className='w-[50%] flex flex-col items-center '>
        {colorTitleWords(title.length > 50 ? (title.substring(0, 30).includes(' ') ? title.substring(0, title.lastIndexOf(' ')) : title.substring(0, 20)) : title)}
        <p className='text-center lg:text-xl  w-[90%] ' style={{ overflowWrap: 'break-word' }}>
          {text.length > 100 ? text.slice(0, 100) : text}...
        </p>
      </div>
      <div className='w-[40%] h-full py-0'>
        <img src={img} className='w-full h-full object-cover rounded-[56px]' alt="" />
      </div>
    </Link>
  );
};

const Pagination = ({ totalPages, onPageChange, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className='my-4'>
      <ul className='flex justify-center'>
        {pageNumbers.map((number) => (
          <li key={number}>
            <button
              onClick={() => onPageChange(number)}
              className={`${currentPage === number ? 'bg-secondary text-white' : ''
                } font-bold py-2 px-4 mx-1 rounded-full hover:bg-secondary border-2 border-secondary hover:text-white`}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};
