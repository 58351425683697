
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Section1({post}) {
  const {t} = useTranslation()
  return (
    <>
      <div className='bg-primary bg-opacity-25 w-full  h-[500px] lg:p-10 p-3 flex items-center justify-center text-white gap-10'>

        <div className='flex flex-col w-[50%] justify-center items-center gap-4'>
          <h1 className='lg:text-4xl text-2xl text-black underline underline-offset-4 text-center font-bold lg:w-[480px] w-[310px] space-y-4 leading-[50px]  break-words'>{ post?.title ?(post?.title.slice(0,50).includes(' ') ? post?.title.split(' ').slice(0, 3).join(' ')  : post?.title.slice(0, 30) ) :t("دليل شركات الطاقة في العالم العربي")}</h1>
          <Link to={post?.id ? `/post/${post?.id}` : "#"}   className='bg-primary hover:bg-opacity-80 duration-200 rounded-md text-center text-xl text-white font-bold lg:w-[300px] w-[200px] h-[50px] flex justify-center items-center mx-auto mt-4'>
            {t("تعرف على المزيد")}
          </Link>
        </div>


        <div className=''>
          <img className='lg:w-[613px] w-[513px] lg:h-[320px]  h-[200px] object-cover lg:rounded-[90px] rounded-[50px]  drop-shadow-md' src={post?.image ? post.image :"/assets/home/Section4.5/p1.jpg"} alt="" />
        </div>

      </div>
    </>
  )
}

export default Section1