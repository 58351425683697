import React, { useEffect, useState } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import Navbar from './components/Navbar';
import PublicRouter from './Routes/PublicRouter';
import Footer from './components/Footer';
import 'flickity/css/flickity.css';
import FloatIcons from './components/FloatIcons.jsx';
import ScrollToTopOnPageChange from './components/ScrollToTop';
import Loader from './components/Loader.jsx';



export default function App() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    Aos.init({duration: 1000});

    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }, [])
  return (
    <>
    {
      loading && <Loader />
    }
    <FloatIcons />
    <ScrollToTopOnPageChange />
    <Navbar />
    <PublicRouter />
    <Footer />
    </>
  )
}

