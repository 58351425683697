// Modal.js

import React from 'react';
import Modal from 'react-modal';




const ModalComponent = ({ isOpen, closeModal ,  children}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={`absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] overflow-y-auto   rounded-lg border-none outline-none bg-[#f7f2fb]  drop-shadow-lg    md:w-[600px]  w-[90%]  lg:w-[800px] `}
      overlayClassName="bg-black bg-opacity-50 w-full h-full fixed top-0 left-0 z-[8]"
      shouldCloseOnOverlayClick={true}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
