import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import postMethod from './../../../functions/postMethod';
import Swal from 'sweetalert2';

function Section1() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});

  const Send = async (e) => {
    e.preventDefault()
    Swal.fire({
      title: 'جاري التحميل...',
      html: 'يرجى الانتظار بينما يتم معالجة الطلب .',
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    })
    const res = await postMethod("/contacts", formData);
  
    if (res?.status === 200 || res?.status === 201) {
      Swal.fire({
        icon: 'success',
        title: 'تم الارسال بنجاح',
      })

      setFormData({});
    } else {
      Swal.fire({
        icon: 'error',
        title: 'لقد حدث خطأ',
      })
    }
  };


  return (
    <>
      <div className='w-[95%] mx-auto flex justify-between gap-4 items-center'>
        <section className='lg:w-[570px] w-[500px] lg:h-[700px] h-[500px]'>
          <form onSubmit={Send} className='w-full'>
            <InputDiv formData={formData} setFormData={setFormData} name="name" placeholder="الاسم" img={"/assets/ContactUs/Group (1).png"}/>
            <InputDiv formData={formData} setFormData={setFormData} name="email" placeholder="البريد الالكتروني (اختياري)" img={"/assets/ContactUs/Vector.png"}/>
            <InputDiv formData={formData} setFormData={setFormData} name="phone_number" placeholder="رقم الهاتف" img={"/assets/ContactUs/Vector (1).png"}/>
            <textarea onChange={(e) => setFormData({...formData, [e.target.name]: e.target.value})} name="message" className='w-full lg:h-[300px] h-[200px] p-6 bg-secondary text-secondary placeholder-secondary placeholder-opacity-30 font-bold border-secondary bg-opacity-[11%] rounded-[50px]' type="text" placeholder={t('قم بكتابة تعليقك')} />
            <button type="submit" className='bg-secondary hover:bg-opacity-80 duration-200 rounded-md text-center text-xl text-white font-bold w-[200px] h-[50px] flex justify-center items-center mx-auto mt-4'>
              {t("ارسال")}
            </button>
          </form>
        </section>

        <section className='lg:w-[750px] w-[550px] lg:h-[700px] h-[500px] flex flex-col gap-2 justify-center items-center'>
          <div className='w-full h-full rounded-xl'>
            <iframe className='w-full h-full rounded-[40px]' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3426.254134751761!2d25.519985215115636!3d29.200873182147344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x147aaf441b073a1f%3A0x306390f7c11ecb1e!2s%D8%B4%D8%B1%D9%83%D8%A9%20%D8%AC%D9%85%D8%B9%D8%A9%20%D9%84%D9%84%D8%AA%D8%B5%D8%AF%D9%8A%D8%B1%20%D9%88%D8%A3%D9%86%D8%B8%D9%85%D8%A9%20%D8%A7%D9%84%D8%B7%D8%A7%D9%82%D8%A9%20%D8%A7%D9%84%D9%85%D8%AA%D8%AC%D8%AF%D8%AF%D8%A9!5e0!3m2!1sen!2seg!4v1649481388052!5m2!1sen!2seg" width={700} height={500} style={{ border: 0 }} allowFullScreen loading="lazy" />
          </div>
          <div className='w-full h-full rounded-xl'>
            <iframe className='w-full h-full rounded-[40px]' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3455.2988541605314!2d30.908831999999997!3d29.999574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDU5JzU4LjUiTiAzMMKwNTQnMzEuOCJF!5e0!3m2!1sen!2seg!4v1715264526193!5m2!1sen!2seg" width={700} height={500} style={{ border: 0 }} allowFullScreen loading="lazy" />
          </div>
          </section>
      </div>
    </>
  );
}

const InputDiv = ({ name, placeholder, img, formData, setFormData }) => {
  const { t } = useTranslation();
  return (
    <div className='relative bg-secondary bg-opacity-[7%] border-0 w-full rounded-full lg:mb-10 mb-4'>
      <img className='absolute top-5 right-7' src={img} alt="" />
      <input name={name} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} type="text" placeholder={t(placeholder)} className='w-full h-full bg-secondary text-secondary placeholder-secondary font-bold border-secondary focus:ring-2 focus:ring-secondary ring-opacity-40 bg-opacity-[11%] rounded-full px-16 py-4' />
    </div>
  );
};

export default Section1;
