import React from 'react'
import Title from '../../components/Title'
import Section1 from './components/Section1'
import Section2 from './components/Section2'
import { useTranslation } from 'react-i18next'

export default function ContactUsPage() {
  const { t } = useTranslation()
  return (
    <>
      <div className='w-full mx-auto mb-10 py-5 '>
        <div className={`w-full flex justify-center items-center text-center`}>
          <h1 className='w-full flex justify-center items-center text-2xl font-bold'>
            {t("احصل علي")}
            <p className={`text-nowrap text-secondary mx-2`}>{t("استشارتك الان !")}</p>
          </h1>
        </div>
      </div>

      <Section1 />
      <Section2 />
    </>
  )
}

