import React from 'react'
import { useTranslation } from 'react-i18next'
import GetMethod from '../../../functions/getMethod'
import { Link } from 'react-router-dom'
import VideoComponent from '../../../components/VideoComponent'

export default function Section4_5() {
  const { t } = useTranslation()
  const LANG = localStorage.getItem('LANGUAGE') === "en"
  const [banner, setBanner] = React.useState(false)

  React.useEffect(() => {
    const getData = async () => {
      const res = await GetMethod("/banners")
      if (res?.status == 200 || res?.status == 201) {
        setBanner(res?.data?.data[0])
      }
    }

    getData()
  },[])
  return (
    banner ? (
      <>

        <section className='bg-primary w-full  lg:h-[500px] h-[300px] lg:p-10 p-3 flex items-center justify-center text-white gap-10 lg:my-20'>

          <div data-aos="fade-right" className='flex flex-col lg:gap-9  gap-5 w-[50%]'>
            <h1 className='lg:text-4xl text-2xl font-bold lg:w-[380px] w-[300px] leading-[40px]'>{banner?.main_title}</h1>
            <a target='_blank' href={banner?.link} className=' text-primary bg-white  flex items-center justify-center  rounded-full  p-2 lg:px-5 px-3 lg:w-[230px] w-[180px] lg:h-[50px]  h-[33px]'>{t("آخر المشروعات")}</a>
            <p className={`lg:w-[80%] lg:text-lg text-sm ${LANG ? "border-l" : "border-r"} border-white p-2 font-semibold`}>{banner?.sub_title}</p>
          </div>


          <div data-aos="fade-left" className=''>
            {
              banner.video ? (
                <div  className={'lg:w-[450px] w-[200px] lg:h-[420px] h-[200px] object-cover rounded-lg  drop-shadow-md'}  >
                <VideoComponent  video_url={banner?.video} cover_url={banner?.image}/>                
                </div>
              ):(
                <img className='lg:w-[450px] w-[200px] lg:h-[420px] h-[200px] object-cover rounded-lg  drop-shadow-md' src={banner?.image} alt="" />                
              )

            }
          </div>

        </section>
      </>
    ) : (

      <section className='bg-primary w-full  lg:h-[500px] h-[300px] lg:p-10 p-3 flex items-center justify-center text-white gap-10 lg:my-20'>

        <div data-aos="fade-right" className='flex flex-col lg:gap-9  gap-5 w-[50%]'>
          <h1 className='lg:text-4xl text-2xl font-bold lg:w-[380px] w-[300px] leading-[40px]'>{t("نعمل على تمكين حلول الطاقة المتجددة")}</h1>
          <div className=' text-primary bg-white  flex items-center justify-center  rounded-full  p-2 lg:px-5 px-3 lg:w-[230px] w-[180px] lg:h-[50px]  h-[33px]'>{t("آخر المشروعات")}</div>
          <p className={`lg:w-[80%] lg:text-lg text-sm ${LANG ? "border-l" : "border-r"} border-white p-2 font-semibold`}>{t("نحن شركة رائدة في توفير حلول الطاقة المتجددة المبتكرة، نستفيد من قوة الطاقة المتجددة والمستدامة لتحويل طريقة تزويد العالم بالطاقة.")}</p>
        </div>


        <div data-aos="fade-left" className=''>
          <img className='lg:w-[450px] w-[200px] lg:h-[420px] h-[200px] object-cover rounded-lg  drop-shadow-md' src="/assets/home/Section4.5/p1.jpg" alt="" />
        </div>

      </section>
    )
  )
}
