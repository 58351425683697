import React from 'react'
import Carousel from '../../../components/Carousel'
import { useTranslation } from 'react-i18next'
// import { t } from 'i18next'

export default function Section6() {
    const {t} = useTranslation()
  return (
    <section className=' mt-20'>
    <h1 className='text-4xl font-bold  text-center '>{t("القطاعات المستهدفة")}</h1>

    <div className='flex items-center lg:gap-5 gap-1 mt-10 w-[80%] mx-auto'>
      <Carousel   customStyles={"gap-5 h-full w-full"} dots={false}  carouselClass="section6">
        <Card color={"from-[#A69331]"} title="قطاع الزراعة" image="/assets/home/section6/p1.jpg" />
        <Card color={"from-[#FA8A5C]"} title="قطاع الصناعة" image="/assets/home/section6/p2.jpg" />
        <Card color={"from-secondary"} title="قطاع الصحة" image="/assets/home/section6/p3.jpg" />
        <Card color={"from-[#450BEA]"} title="قطاع التعليم" image="/assets/home/section6/p4.jpg" />
        <Card color={"from-[#EDE7FD]"} title="قطاع التجارة" image="/assets/home/section6/p7.jpg" />
        <Card color={"from-primary"} title="القطاع السكني" image="/assets/home/section6/p5.jpg" />
        <Card color={"from-[#136D35]"} title="القطاع التجاري" image="/assets/home/section6/p6.jpg" />
      </Carousel>
    </div>

  </section>
  )
}




function Card({ title, image,color }) {
  const {t} = useTranslation()

  return (
    <div className={`carousel-cell mx-5 lg:w-[300px] w-[150px]  lg:h-[500px] h-[280px] rounded-tr-[100px] rounded-b-[100px] flex flex-col items-center bg-gradient-to-bl   ${color} to-white gap-5 overflow-hidden`}>
      <div className='bg-white w-full lg:h-[400px] h-[200px] lg:rounded-tr-[200px] rounded-tr-[100px] lg:rounded-bl-[200px] rounded-bl-[100px] overflow-hidden'>
        <img className='w-full h-full object-cover' src={image} alt="" />
      </div>


      <h1 className='text-center lg:text-2xl font-bold'>{t(title)}</h1>
    </div>
  )

}