import React, { useEffect, useState } from 'react'
import Title from '../../components/Title'
import Section1 from './components/Section1'
import Section2 from './components/Section2'
import GetMethod from '../../functions/getMethod'

export default function BlogPage() {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    
    const getData = async () => {
      const res = await GetMethod("/posts");
      if(res?.status == 200 || res?.status == 201){
        setPosts(res?.data?.data)
      }
    }

    getData()
  },[])


  return (
    <>
    <div className='xl:mb-10 my-6'>
        <Title title="المدونة" center={true} />
    </div>
  

      <Section1 post={posts[posts.length - 1]} />
      <Section2 postsList={posts}/>
    </>
  )
}
