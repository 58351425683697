import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Section4() {
  const { t } = useTranslation();
  return (
<section className='relative    xl:h-[610px] xl:w-[1400px] lg:h-[550px] md:w-[80%] sm:w-full w-[95%] h-[350px] mx-auto z-[1] flex flex-col items-center justify-center my-10'>
  {/* <img className='mx-auto absolute lg:top-0 -top-16 xl:-left-40 lg:-left-24 -left-12 w-full h-full z-[-9]  overflow-visible' src="/assets/home/section4/v1.svg" alt="" /> */}

  <div class="object-cover xl:w-[50%] w-[80%] h-full skew-y-[-5deg] skew-x-[-8deg] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
    <div className='relative w-full h-full'>

    <div className='object-cover w-full h-[90%] bg-gradient-to-bl from-[#06b6d4] to-[#022c33] transform  skew-y-[-5deg] skew-x-[-8deg] '></div>
    <div className='object-cover w-[102%] h-[93%] bg-gradient-to-bl from-[#caecf1] to-[#a1e1ec] transform  skew-y-[-5deg] skew-x-[-8deg] absolute  top-0 left-0 z-[-1] ' ></div>
    </div>
</div>




      <div className='text-white flex flex-col gap-4 items-center justify-center lg:w-[60%] w-[80%]'>

        <h1 data-aos="fade-up"  className='lg:text-3xl md:text-xl font-bold text-center mt-2'>{t("المسؤولية البيئية في قلب عملنا")}</h1>
        <p data-aos="fade-up" data-aos-delay="200"  className=' lg:w-[60%] lg:text-lg md:text-sm text-xs text-center md:w-[60%] w-[70%]'>{t("نعمل علي تصميم وتركيب أنظمة الطاقة المتجددة النظيفة وأحدث الأنظمة التكنولوجية دون أن نتسبب في أضرار بيئية")}</p>

        

        {/* <img className='lg:w-full w-[90%] lg:h-auto h-[150px] object-cover p-1 rounded-xl bg-white drop-shadow-lg' src="/assets/home/section4/p1.jpg" alt="" /> */}
        <img data-aos="fade-up" data-aos-delay="400"  className='xl:w-[500px] w-[250px] xl:h-[300px] h-[150px] object-cover  p-1 rounded-xl bg-white drop-shadow-lg' src="/assets/home/section4/p1.jpeg" alt="" />

      </div>
    </section>
  );
}
